<template>
  <NavBar />
  <div id="content-container">
    <router-view />
  </div>
  <FooterVue />
</template>
<script>
import { defineComponent } from "vue";
import NavBar from "./components/Nav";
import FooterVue from "./components/Footer.vue";

export default defineComponent({
  name: "CareBehavioralHealth",
  components: {
    NavBar,
    FooterVue,
  },
});
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
@media (min-width: 901px) {
  #content-container {
    margin-top: 96px;
  }
}
</style>
