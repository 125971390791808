<template>
  <div id="services-container">
    <StandardPageHeader
      :icon="'/icons/team-collaboration-icon.svg'"
      :title="'Professional Development Services'"
    />
    <div id="professional-development-blurb" class="text-blue">
      CARE Behavioral Health provides trainings on specific “gold standard”
      instruments that are typically used to evaluate individuals suspected of
      having ASD. They also provide workshops on how to differentiate ASD from
      other disorders and how to identify co-morbid conditions that are common
      to individuals with ASD. Staff development training is offered by CARE BH
      on how to conduct Functional Behavior Assessments (FBAs) and Behavior
      Intervention Plans (BIPs) in order to address challenging behaviors
      exhibited by students.
    </div>
    <div
      v-for="(section, sectionKey) in DevelopmentServices"
      :key="sectionKey"
      class="list-section mlr-auto"
    >
      <h1 class="text-blue">{{ makeTitle(sectionKey) }}</h1>
      <LinkList
        class="flex column"
        :link-section="section"
        :text-color-class="'text-link-blue'"
        :internal="true"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { titleize } from "../utils/titleize";
import { ProfessionalDevelopmentServices } from "../constants/services";
import StandardPageHeader from "./StandardPageHeader.vue";
import LinkList from "./LinkList.vue";

export default defineComponent({
  name: "ProfessionalDevelopment",
  components: {
    LinkList,
    StandardPageHeader,
  },
  data() {
    return {
      DevelopmentServices: ProfessionalDevelopmentServices,
    };
  },
  methods: {
    makeTitle(string: string) {
      return titleize(string);
    },
  },
});
</script>
<style>
@media (min-width: 901px) {
  #header-icon {
    height: 10em;
    width: 10em;
    filter: opacity(40%) invert();
    margin: 3em;
  }
  #header-title {
    font-size: 3em;
    font-weight: 800;
  }
  #services-container {
    background-color: white;
  }
  #professional-development-blurb-container {
    height: 16em;
  }
  #professional-development-blurb {
    width: 800px;
    margin: 4em auto;
  }
  /* .list-section {
    width: 70%;
  } */
}
@media (max-width: 900px) {
  #header-icon {
    height: 10em;
    width: 10em;
    filter: opacity(40%) invert();
    margin: 3em;
  }
  #services-container {
    background-color: white;
  }
  #professional-development-blurb-container {
    height: 16em;
  }
  #professional-development-blurb {
    width: 90%;
    max-width: 600px;
    margin: 4em auto;
  }
  /* .list-section {
    width: 90%;
    max-width: 600px;
  } */
}
</style>
