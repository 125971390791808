<template>
  <div v-if="internal" class="link-list-container">
    <router-link
      :key="index"
      v-for="(item, index) in linkSection"
      class="list-link mb-1"
      :class="textColorClass"
      :to="`${route.path}/${item.route}`"
    >
      {{ item.title ? item.title : item.source }}&nbsp;>
    </router-link>
  </div>
  <div v-else class="link-list-container">
    <a
      :key="index"
      v-for="(item, index) in linkSection"
      class="list-link mb-1"
      :class="textColorClass"
      :href="item.url"
      target="_blank"
    >
      <div>
        <div>{{ item.title }}</div>
        <div class="text-white">{{ item.source }}</div>
      </div>
    </a>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "LinkList",
  props: {
    linkSection: {
      type: Array,
      required: true,
    },
    textColorClass: {
      type: String,
      required: true,
    },
    internal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      route: useRoute(),
    };
  },
});
</script>
<style scoped>
.link-list-container {
  margin-right: auto;
  margin-left: 2em;
}
@media (min-width: 901px) {
  .link-list-container {
    width: 70%;
  }
  a {
    margin-bottom: 1.5em;
  }
  .list-link {
    margin-left: 1em;
    font-size: clamp(16px, 1.5vw, 1.75vw);
  }
}
@media (max-width: 900px) {
  a {
    margin-bottom: 1.5em;
  }
  .link-list-container {
    width: 90%;
  }
}
</style>
