<template>
  <div id="core-values-container" class="text-blue">
    <div class="core-values-header section-max-w mlr-auto">The Three C's</div>
    <div
      class="core-values-list-container flex j-evenly section-max-w mlr-auto"
    >
      <div
        :key="value.title"
        v-for="value of coreValues"
        class="core-value-item"
      >
        <div class="value-title static-large">{{ value.title }}</div>
        <div class="value-content">
          {{ value.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CoreValues",
  data() {
    return {
      coreValues: [
        {
          title: "Collaboration",
          text: "We promote positive outcomes by motivating and empowering everyone we partner with and serve with integrity, supportive leadership, and mentoring.",
        },
        {
          title: "Competence",
          text: "We focus on creative and innovative training for professionals so they can confidently and competently advance their knowledge and skill sets.",
        },
        {
          title: "Compassion",
          text: "We promote a culture of honest, relatable, and adaptable professionals that are respectful of each other and those we serve.",
        },
      ],
    };
  },
});
</script>
<style>
#core-values-container {
  margin: 4em 0;
}
.core-values-list-container {
  max-width: 90vw;
}
.core-values-header {
  text-align: center;
  padding: 0.25em;
  margin-bottom: 0.5em;
  font-size: 3em;
  background: var(--care-blue-faint);
}
.core-value-item {
  padding: 1em;
  width: 300px;
}
@media screen and (min-width: 701px) {
  .value-title {
    text-align: center;
  }
  .value-content {
    text-align: center;
  }
}
@media screen and (max-width: 700px) {
  .core-values-list-container {
    flex-wrap: wrap;
  }
}
</style>
