import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex j-center"
}
const _hoisted_2 = {
  key: 1,
  class: "flex j-center"
}
const _hoisted_3 = {
  key: 2,
  class: "flex j-center"
}
const _hoisted_4 = {
  key: 3,
  class: "flex j-center"
}
const _hoisted_5 = {
  key: 4,
  class: "flex j-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderContent = _resolveComponent("HeaderContent")!
  const _component_ParagraphContent = _resolveComponent("ParagraphContent")!
  const _component_BulletContent = _resolveComponent("BulletContent")!
  const _component_CompoundBulletContent = _resolveComponent("CompoundBulletContent")!
  const _component_NoteContent = _resolveComponent("NoteContent")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service.content, (item, itemIndex) => {
    return (_openBlock(), _createElementBlock("div", { key: itemIndex }, [
      (item.type === 'header')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_HeaderContent, {
              "header-text": item.value
            }, null, 8, ["header-text"])
          ]))
        : (item.type === 'paragraph')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ParagraphContent, {
                "paragraph-text": item.value
              }, null, 8, ["paragraph-text"])
            ]))
          : (item.type === 'bullets')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_BulletContent, {
                  "bullet-data": item.value
                }, null, 8, ["bullet-data"])
              ]))
            : (item.type === 'compoundBullets')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_CompoundBulletContent, {
                    "bullet-data": item.value
                  }, null, 8, ["bullet-data"])
                ]))
              : (item.type === 'note')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_NoteContent, {
                      noteText: item.value
                    }, null, 8, ["noteText"])
                  ]))
                : _createCommentVNode("", true)
    ]))
  }), 128))
}