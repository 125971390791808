<template>
  <div :key="itemIndex" v-for="(item, itemIndex) in service.content">
    <div v-if="item.type === 'header'" class="flex j-center">
      <HeaderContent :header-text="item.value" />
    </div>
    <div v-else-if="item.type === 'paragraph'" class="flex j-center">
      <ParagraphContent :paragraph-text="item.value" />
    </div>
    <div v-else-if="item.type === 'bullets'" class="flex j-center">
      <BulletContent :bullet-data="item.value" />
    </div>
    <div v-else-if="item.type === 'compoundBullets'" class="flex j-center">
      <CompoundBulletContent :bullet-data="item.value" />
    </div>
    <div v-else-if="item.type === 'note'" class="flex j-center">
      <NoteContent :noteText="item.value" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import BulletContent from "./BulletContent.vue";
import ParagraphContent from "./ParagraphContent.vue";
import HeaderContent from "./HeaderContent.vue";
import NoteContent from "./NoteContent.vue";
import CompoundBulletContent from "./CompoundBulletContent.vue";
export default defineComponent({
  name: "DynamicContent",
  components: {
    HeaderContent,
    ParagraphContent,
    BulletContent,
    CompoundBulletContent,
    NoteContent,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
});
</script>
