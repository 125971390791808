<template>
  <div id="homepage-welcome" class="h-inherit">
    <div id="welcome-info-container">
      <div class="page-blurb">
        <div class="welcome-header highlight-font-size">
          CARE Behavioral Health
        </div>
        <div class="welcome-subheader">
          Consultative and Professional Development Services
        </div>
      </div>
      <router-link to="/services" id="welcome-button" class="button-link">
        See how CARE <span class="no-wrap-text">can help &nbsp;&nbsp;></span>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "WelcomeBanner",
});
</script>
<style scoped>
/* TODO: CLEAN UP DUPLICATES */
@media (min-width: 901px) {
  #homepage-welcome {
    height: 40em;
    background-image: url("/public/images/training-photo-website-.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left 80%;
  }

  #welcome-info-container {
    position: relative;
    width: fit-content;
    top: 60%;
    left: 5%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    color: var(--care-yellow-primary);
  }

  #welcome-button {
    width: fit-content;
  }

  .page-blurb {
    background-image: linear-gradient(
      to right,
      var(--care-blue-primary) 40%,
      var(--care-blue-opaque) 60%,
      transparent
    );
    width: fit-content;
    padding: 1em 10em 1em 1.5em;
    margin-bottom: 1em;
  }
  .welcome-subheader {
    color: white;
  }
}
@media (max-width: 900px) {
  #homepage-welcome {
    height: 27em;
    background-image: url("/public/images/training-photo-website-.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 10% 20%;
  }

  #welcome-info-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    box-sizing: border-box;
  }

  #welcome-button {
    width: fit-content;
    align-self: flex-end;
    color: var(--care-yellow-primary);
  }

  .page-blurb {
    height: 80%;
    width: fit-content;
  }
  .welcome-header {
    color: var(--care-blue-primary);
    font-size: 8.5vw;
  }
  .welcome-subheader {
    color: var(--care-blue-primary);
    width: 225px;
  }
}
</style>
