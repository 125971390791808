<template>
  <div id="services-container">
    <StandardPageHeader
      :icon="'/icons/work-experience-icon.svg'"
      :title="'Consultative Services'"
    />
    <div id="professional-development-blurb" class="text-blue">
      CARE Behavioral Health works directly with the school district to tailor
      the support they need to make their students and staff members successful.
      CARE BH provides training on evidenced-based approaches and practices for
      students with ASD and related disorders as well as provides training on
      how to work effectively with students with and without disabilities who
      are engaging in challenging behavior. CARE BH has also developed a more
      intensive technical assistance model called the Collaborative Technical
      Assistance and Support (CTAS) that provides ongoing coaching and support
      to a school district striving to make sustainable system changes while
      building capacity.
    </div>
    <div
      v-for="(section, sectionKey) in Consultations"
      :key="sectionKey"
      class="list-section mlr-auto"
    >
      <h1 class="text-blue">{{ makeTitle(sectionKey) }}</h1>
      <LinkList
        class="flex column"
        :link-section="section"
        :text-color-class="'text-link-blue'"
        :internal="true"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { titleize } from "../utils/titleize";
import { ConsultationServices } from "../constants/services";
import LinkList from "./LinkList.vue";
import StandardPageHeader from "./StandardPageHeader.vue";
export default defineComponent({
  name: "ConsultationsVue",
  components: {
    LinkList,
    StandardPageHeader,
  },
  data() {
    return {
      Consultations: ConsultationServices,
    };
  },
  methods: {
    makeTitle(string: string) {
      if (string === "inclusiveEducationPractices") {
        return `Other District-Level Offerings:
        Inclusive Education Practices`;
      }
      return titleize(string);
    },
  },
});
</script>
<style>
#services-container {
  background-color: white;
}
</style>
