<template>
  <div id="standard-header" class="back-blue flex align-c">
    <div id="header-info" class="flex align-c">
      <img id="header-icon" :src="icon" alt="dev icon" />
      <div id="header-title" class="text-white">{{ title }}</div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { titleize } from "../utils/titleize";

export default defineComponent({
  name: "StandardPageHeader",
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    makeTitle(string: string) {
      return titleize(string);
    },
  },
});
</script>
<style scoped>
@media (min-width: 901px) {
  #standard-header {
    min-height: fit-content;
  }
  #header-info {
    max-width: 90vw;
  }
  #header-icon {
    height: 10em;
    width: 10em;
    filter: opacity(40%) invert();
    margin: 3em;
  }
  #header-title {
    font-size: 3em;
    font-weight: 800;
  }
}
@media (max-width: 900px) {
  #standard-header {
    min-height: fit-content;
    width: 100%;
  }
  #header-info {
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
  #header-icon {
    position: absolute;
    left: 0%;
    height: 20vw;
    width: 20vw;
    filter: opacity(10%) invert();
    margin: 2em;
  }
  #header-title {
    font-size: clamp(2vw, 6vw, 7vw);
    font-weight: 800;
    margin: 1em 0 1em 3em;
  }
}
</style>
