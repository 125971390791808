export type CompoundBullet = {
  header: string;
  list: string[];
};

export type BulletList = {
  header: string;
  list: string[];
  numbered: boolean;
};

export type CompoundBulletList = {
  header: string;
  list: CompoundBullet[];
};

export type ContentItem =
  | { type: "bullets"; value: BulletList }
  | { type: "compoundBullets"; value: CompoundBulletList }
  | { type: "header"; value: string }
  | { type: "paragraph"; value: string }
  | { type: "note"; value: string };

type ServiceContent = ContentItem[];
export interface CAREService {
  title: string;
  route: string;
  category: string;
  content: ServiceContent;
  length?: string;
  lengthNote?: string;
  participants?: { header: string; list: string[] };
  new?: boolean;
}

interface ProfessionalDevelopmentServices {
  trainingForEvaluationPersonnel: CAREService[];
  // otherOfferings: CAREService[];
}
interface ConsultationServices {
  otherTypesOfClassroomConsultativeSupport: CAREService[];
  districtServices: CAREService[];
  // otherDistrictLevelOfferings: CAREService[];
  inclusiveEducationPractices: CAREService[];
}

export const ProfessionalDevelopmentServices: ProfessionalDevelopmentServices =
  {
    trainingForEvaluationPersonnel: [
      {
        title:
          "Facilitated Training on the Autism Diagnostic Observation Schedule-2 (ADOS-2)",
        new: false,
        category: "professional-development",
        route: "ADOS-2",
        length: "2 Days",
        participants: {
          header: "Evaluation personnel",
          list: [
            "School Psychologists",
            "School Counselors",
            "Educational Diagnosticians",
            "OTs",
            "SLPs",
            "Behavior Specialists",
            "Administrators",
            "Teachers",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "The ADOS-2 (2012) is a semi-structured, standardized assessment of communication, social interaction, play, and restricted and repetitive behaviors. This revision improves an instrument viewed as “the gold standard” for assessment of Autism Spectrum Disorder (ASD) and related disorders. ADOS-2 has added the Toddler module that is used with very young children and three of the four existing modules have been updated. CARE BH's facilitated training provides school evaluation personnel with training on how to code and score the ADOS-2. The training will also outline how to incorporate the instrument into a comprehensive team-based evaluation in order to determine if the student meets eligibility criteria for ASD under state guidelines.",
          },
        ],
      },
      //TODO 7/15: ADD FORMATTING FOR ABBREVIATIONS
      {
        title: "Autism Diagnostic Interview-Revised (ADI-R)",
        new: false,
        category: "professional-development",
        route: "ADI-R",
        length: "1-2 Days",
        participants: {
          header: "Evaluation personnel",
          list: [
            "School Psychologists",
            "School Counselors",
            "Educational Diagnosticians",
            "OTs",
            "SLPs",
            "Behavior Specialists",
            "Administrators",
            "Teachers",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "The ADI-R is a comprehensive interview, which provides a thorough assessment of individuals suspected of having autism or other related disorders. The ADI-R has proven highly useful for formal diagnosis as well as treatment and educational planning.",
          },
          {
            type: "paragraph",
            value:
              "The ADI-R is also considered a “gold standard” instrument and is highly recommended for use with the ADOS-2. It provides an opportunity for caregivers to provide detailed input on their child's current behaviors and early developmental milestones.",
          },
          {
            type: "paragraph",
            value:
              "A trained professional will interview the caregiver to ensure they have been actively involved in the evaluation process.",
          },
        ],
      },
      {
        title: "ADOS-2 Live Administration",
        new: false,
        category: "professional-development",
        route: "ADOS-2-Live",
        length: "1 Day",
        participants: {
          header:
            "Evaluation personnel that have previously received training on the ADOS-2",
          list: [
            "School Psychologists",
            "Educational Diagnosticians",
            "School Counselors",
            "SLPs",
            "OTs",
            "Behavior Specialists",
            "Teachers",
            "Administrators",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "In this training, two live administrations of the ADOS-2 will be conducted by a “clinical reliable administrator”. Participants will observe and take notes during each assessment, then code and score the module using the algorithms. The participants will compare their codes and scoring to the person administering the ADOS-2. This training is one way to assist school-based evaluators with establishing inter-rater reliability on this instrument.",
          },
        ],
      },
      {
        title: "Child Autism Rating Scale, Second Edition (CARS-2)",
        new: false,
        category: "professional-development",
        route: "CARS-2",
        length: "1 Day",
        participants: {
          header: "",
          list: [
            "Evaluation Personnel (SLPs, OTs, LSSPs, Ed Diagnosticians, Social Workers, Behavior Specialists) ",
            "Anyone conducting assessments and comprehensive team-based assessments in public school settings",
            "Educators and special educators working with students with Autism",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "The CARS-2 revision was published by Western Psychological Services (WPS) in 2010. The CARS-2 has become one of the most widely used and empirically validated autism assessments. It has proven especially effective in discriminating between children with autism and those with severe cognitive deficits, and in distinguishing mild-to-moderate from severe autism. During this training participants will learn how the CARS-2 expands the test's clinical value, making it more responsive to individuals on the “high-functioning” end of the autism spectrum (i.e. those with average or higher IQ scores, better verbal skills, and more subtle social and behavioral deficits). Participants will become familiar with all three forms included with the CARS-2 and learn how it is useful in identifying symptoms of autism.",
          },
        ],
      },
      {
        title:
          "Implications for Evaluating Girls Suspected of having Autism Spectrum Disorder",
        new: true,
        category: "professional-development",
        route: "implications-for-girls-suspected-of-autism",
        length: "1 Day",
        participants: {
          header: "",
          list: [
            "Evaluation personnel who evaluate individuals suspected of ASD",
            "educators and others who serve individuals with Autism Spectrum Disorders in the public- and community-based settings",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "While the prevalence rate for students with ASD continues to increase, the number of girls identified with ASD has continued to remain constant over the decades. Boys with ASD are diagnosed four times more often than girls. There are several reasons in the literature suggesting why girls on the spectrum are being underidentified.",
          },
          {
            type: "paragraph",
            value:
              "Participants will learn why there is a gender gap in the identification of ASD and if a referral bias may exist and if so, what can we do about it. Participants will also learn how stereotypical female traits may impact receiving a diagnosis of ASD. Participants will also learn about the terminology used to describe how girls on the spectrum behave and how it manifests ultimately contributing to misdiagnoses and/or later diagnosis of ASD than their male counterparts. The workshop will also cover common co-morbid conditions that girls with ASD are more likely to have.",
          },
          {
            type: "paragraph",
            value:
              "The workshop will conclude with practical things that educators and evaluators can do to ensure that girls who are identified on the spectrum get the support they need to successfully navigate through school and have positive post-school outcomes.",
          },
        ],
      },
      {
        title:
          "Comprehensive Team-Based Evaluations of Autism Spectrum Disorder",
        new: false,
        category: "professional-development",
        route: "team-based-evals",
        length: "1-2 Days",
        participants: {
          header: "",
          list: [
            "School Psychologists",
            "Educational Diagnosticians",
            "SLPs",
            "Behavior Specialists",
            "Counselors",
            "OTs",
            "Other assessment personnel including staff serving students with ASD",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "School district personnel are faced with an increasing number of requests for evaluations to rule out ASD in students aged 3-21. This training will provide experienced assessment personnel and those who are entering the field with a process for establishing comprehensive team-based evaluations of ASD while following standards of best practice. An overview of ASD as described in the DSM-5 (APA, 2013) and a comparison with educational eligibility criteria under state guidelines will be explored. Different models for forming school teams will be discussed as well as how to plan for the evaluation including how to select instruments, conduct observations, interviews and when to use Autism specific instruments during the evaluation process will be covered. How to write an evaluation report that integrates findings from multiple sources and links recommendations to evidenced-based interventions that have support for use with students with ASD will be discussed.",
          },
        ],
      },
      {
        title:
          "How to Conduct Observations and Interviews as part of a Comprehensive Team-Based Evaluation of Students Suspected of Autism Spectrum Disorder",
        new: false,
        category: "professional-development",
        route: "observations-and-interviews-in-team-based-evals",
        length: "1 Day",
        participants: {
          header: "",
          list: [
            "Evaluation Personnel (SLPs, OTs, LSSPs, Ed Diagnosticians, Social Workers, Behavior Specialists) ",
            "Anyone conducting assessments and comprehensive team-based assessments in public school settings",
            "Educators and special educators working with students with Autism",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "During this session, participants will learn how to conduct structured observations of students suspected of having an ASD across settings and how to conduct interviews with students, parents, and other staff members as part of the comprehensive team-based evaluation. Several formalized assessments will be shared during this session that focus on interviewing students including the Autism Diagnostic Interview –Revised (ADI-R) The ADI-R is a comprehensive interview that has proven highly useful for formal diagnosis and educational eligibility as well as treatment and educational planning. An experienced clinical interviewer questions a parent or caregiver who is familiar with the developmental history and current behavior of the individual being evaluated. The Autism Diagnostic Observation Schedule-2 (ADOS-2) , considered a “gold standard” autism assessment instrument, also has interview questions that can be utilized with students who are verbally fluent. Other formal and informal interviews such as the Childhood Autism Rating Scale -2 Caregiver Parent Questionnaire (CARS-2 CPQ) will be discussed during this training. It is an unscored form completed by the parent or caregiver of the individual being assessed and includes the individual's early development; social, emotional, and communication skills; repetitive behaviors; play and routines; and unusual sensory interests.",
          },
        ],
      },
      {
        title:
          "Controversies in Treatment of Childhood and Adolescent Disorders",
        new: false,
        category: "professional-development",
        route: "controversies-in-treatment",
        length: "1 Full Day",
        participants: {
          header: "Evaluation personnel",
          list: [
            "School Psychologists",
            "School Counselors",
            "Educational Diagnosticians",
            "OTs",
            "PTs",
            "SLPs",
            "Behavior Specialists",
            "Administrators",
            "Teachers",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "School evaluation personnel and practitioners are often tasked with treating students who have disorders that may or may not fall under an educational eligibility under state eligibility guidelines. Some of these students may qualify for 504s and others may not qualify for services yet teachers and related service personnel want to be knowledgeable of these students and the diagnoses they carry so they can identify and plan effective interventions and treatments while being cautious not to use unsupported and/or dangerous treatments that are sometimes prescribed for students with these disorders.",
          },
          {
            type: "bullets",
            value: {
              header: "Participant Will Be Able To",
              numbered: false,
              list: [
                "Identify empirically supported, promising, and unsupported treatments for ADHD",
                "The status of treatment for Autism Spectrum Disorder (ASD)",
                "Use of Attachment Therapy as a mental health intervention",
                "Harmful Treatments, Effective Intervention and Novel Strategies for the Antisocial Behavior of Children and Adolescents",
              ],
            },
          },
        ],
      },
      {
        title:
          "“Best Practices” in the Evaluation of Students Suspected of Having Emotional Disturbance",
        new: false,
        category: "professional-development",
        route: "best-practices-in-evals",
        length: "1 Day",
        participants: {
          header: "Evaluation personnel",
          list: [
            "School Psychologists",
            "School Counselors",
            "Educational Diagnosticians",
            "OTs",
            "SLPs",
            "Behavior Specialists",
            "Administrators",
            "Teachers",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "This session will cover “best practices” in the evaluation of students suspected of having Emotional Disturbance (ED) and how to rule out Autism Spectrum Disorder (ASD) and other educational eligibilities as part of the evaluation process. Participants will learn how to use a checklist that was designed in relation to the various criteria in the definition of ED and allows school-based team teams a systematic approach as part of the decision-making process for identifying students as eligible under the IDEA Classification of Emotional Disturbance (ED). Various recent court decisions will also be covered related to students with ED that may impact the evaluation process.",
          },
        ],
      },
      {
        title:
          "Developing Strategies and Plans for Working Effectively with Students Who Are Exhibiting Challenging Behaviors in the School Setting",
        new: false,
        category: "professional-development",
        route: "strategies-and-plans-for-challenging-behaviors",
        length: "1 Day",
        participants: {
          header: "Evaluation personnel",
          list: [
            "School Psychologists",
            "School Counselors",
            "Educational Diagnosticians",
            "OTs",
            "SLPs",
            "Behavior Specialists",
            "Administrators",
            "Teachers",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              //CHANGED
              "School personnel are facing an increasing number of students with and without disabilities who are exhibiting significant challenging behaviors in the school setting. This session will cover proactive strategies for dealing effectively with students who persistently engage in problem behavior that interferes in their learning or the learning of others. Participants will learn how to conduct Functional Behavior Assessments (FBAs), develop, implement, monitor and evaluate Behavior Intervention Plans (BIPs) that are designed to teach students new ways to behave using socially acceptable behaviors that allow them to remain in the Least Restrictive Environment (LRE). The session will also cover how to develop and implement crisis intervention plans when all other steps of the BIP have been exhausted. Legal implications and responsibilities of school personnel will also be discussed.",
          },
        ],
      },
      {
        title:
          "Developing Written Behavioral Recommendations for Students with Autism and/or ADHD As Part of the Evaluation Process",
        new: false,
        category: "professional-development",
        route: "written-recommendations-in-eval-process",
        length: "1 Day",
        participants: {
          header: "Evaluation personnel",
          list: [
            "School Psychologists",
            "School Counselors",
            "Educational Diagnosticians",
            "OTs",
            "SLPs",
            "Behavior Specialists",
            "Administrators",
            "Teachers",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "School and evaluation personnel are faced with evaluating an increasing number of students who are suspected of having ASD and/or ADHD. Regardless of the educational eligibility that these students may qualify for, evaluation personnel are responsible for conducting evaluations that includes recommendations for addressing their challenging behaviors. “Best practices” for assessing behavior using direct and indirect measures as well as how to instruct students with ASD and/or ADHD will be covered in this session. Participants will also learn how to write appropriate behavioral recommendations in the evaluation report that can be translated into measurable and observable goals, step-by-step plans for teaching functional equivalent and/or replacement behaviors as well as how to monitor and evaluate effectiveness of those recommendations will be discussed.",
          },
        ],
      },
      {
        title: "Functional Communication Training (FCT)",
        new: false,
        category: "professional-development",
        route: "FCT",
        length: "1 Day",
        participants: {
          header: "Evaluation Personnel",
          list: [
            "SLPs",
            "AT Specialists",
            "Behavior Specialists",
            "Classroom Teachers",
            "Counselors",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "Participants will learn about the importance of incorporating Functional Communication Training (FCT) into all aspects of their students' educational programming. FCT has been identified as one of the most effective and common interventions for addressing severe problem behaviors in students with Autism Spectrum Disorder.",
          },
          {
            type: "paragraph",
            value:
              "During this workshop, participants will receive an overview of the Picture Exchange Communication System (PECS) developed by Lorri Frost and Andy Bondy in 2002. It is a evidenced-based practice based on Applied Behavior Analysis (ABA), verbal behavior, and typical language development. Using videos, presenter demonstrations, and role-play scenarios, participants will gain an understanding of how to implement the six phases of PECS with their students. They will also learn how to determine which students are a good candidate for PECS and how to teach their students to spontaneously request, discriminate between pictures/icons, increase from single picture requests to full sentence requests by expanding their vocabulary, along with how to respond to questions and make comments. Participants will also learn how to create an environment that promotes the use of language by creating communication temptations/sabotages and opportunities to use and expand their functional communication skills across the school day. The session will also provide tips for troubleshooting common problems that may arise while implementing PECS in the school setting.",
          },
          {
            type: "paragraph",
            value:
              "Participants will gain an understanding of the importance of prioritizing functional communication training in their classrooms whether they are using an evidenced-based program like PECS or some other means to promote functional communication skills of their students across the school day as well as when to transition to other communication modalities such as speech or AAC devices).",
          },
        ],
      },
      {
        title: "Teaching Critical Communication Skills",
        new: false,
        category: "professional-development",
        route: "critical-communication-skills",
        length: "1 Day",
        participants: {
          header: "",
          list: [
            "School Psychologists",
            "Counselors",
            "SLPs",
            "Social Workers",
            "Teachers (General and Special Education)",
            "Evaluation Personnel responsible for developing BIPs",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "Participants will learn about the importance of incorporating the teaching of the identified ten critical communication skills into all aspects of their students' educational programming.",
          },
          {
            type: "paragraph",
            value:
              "Participants will gain an understanding of the importance of prioritizing teaching these ten critical communication skills by learning a step-by-step approach that can be taught as a goal for instruction and/or as part of a Behavior Intervention Plan (BIP) as a means to increase prosocial communication skills as an effective replacement behavior or a functional equivalent behavior while decreasing problematic behaviors often exhibited by students with ASD regardless of their level of functioning. Participants will learn how to incorporate preventative strategies for teaching the skill along with how to teach each critical communication skill and then provide reinforcement for using the critical communication skill across the school day. Materials for teaching each skill will also be provided during the training.",
          },
        ],
      },
      {
        title:
          "Social Communication (Pragmatics): Linking Assessment to Intervention",
        new: false,
        category: "professional-development",
        route: "social-communication",
        length: "1-2 Days",
        participants: {
          header: "Evaluation Personnel",
          list: ["SLPs", "Counselors", "Teachers", "Behavior Specialists"],
        },
        content: [
          {
            type: "paragraph",
            value:
              "This workshop is designed for assessment personnel who conduct evaluations of students suspected of having ASD, language and communication disorders and related disorders. The second day is also appropriate for those professionals (teachers, counselors, social workers, and others) who provide direct intervention and support to individuals with ASD and/or communication disorders.",
          },
          {
            type: "bullets",
            value: {
              header: "Day 1",
              numbered: false,
              list: [
                "Social Communication (Pragmatics): Understand and improve the ability to evaluate social-communication deficits in students suspected of having ASD or with ASD. The types of formal and informal assessments evaluators may choose to use as part of the evaluation will be discussed.",
              ],
            },
          },
          {
            type: "bullets",
            value: {
              header: "Day 2",
              numbered: false,
              list: [
                //CHANGED
                "Linking Assessment to Intervention: Understand and improve the ability to treat social-communication deficits in students suspected of having ASD or with ASD. Resources, interventions and curricula commonly used to address deficits in pragmatics (social use of language) will be reviewed.",
              ],
            },
          },
        ],
      },
      {
        title:
          "SCERTS Model: Implementation of Multi-Disciplinary Educational Approach Designed for Students with Autism and Related Disorders",
        new: false,
        category: "professional-development",
        route: "SCERTS",
        length: "2 Days (Day 1: Overview & Day 2: Assessment Process)",
        participants: {
          header: "Evaluation Personnel",
          list: [
            "SLPs",
            "OTs",
            "Counselors",
            "Teachers",
            "Behavior Specialists",
            "Parents",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "Participants will learn about the Social Communication, Emotional Regulation, Transactional Support (SCERTS) model (Prizant, Wetherby, Rubin & Laurent, 2007), which is a comprehensive, multidisciplinary educational approach designed for students with Autism Spectrum Disorder and other learners with social emotional differences. During the first part of day one, the workshop will focus on an introduction of the neuro-developmental foundation of SCERTS, an overview of the research to support it, as well as the core domains of the model. Participants will learn how team members along with parents can provide the proper level of support needed to meet the learners who are at the pre-symbolic communicators (nonverbal), emerging language learners (some words and/or phrases) and for those at conversational levels (using words and speech fluently).",
          },
          {
            type: "paragraph",
            value:
              "During the second part of day one, participants will learn how the SCERTS model can create learning environments that promote evidence-based Social Communication (SC) and Emotional Regulation (ER) outcomes in students using transactional supports that are aligned with their development needs and unique learning styles. The participants will learn how Transactional Supports for whole group settings to enhance active engagement using the SCERTS Observation & Action Planning Form. Participants will then develop SC and ER goals as well as a SCERTS Activities Planner for an individual student. This tool provides multidisciplinary team members with a coordinated plan for providing interpersonal and learning supports that foster an individual's SC and ER.",
          },
          {
            type: "paragraph",
            value:
              "On Day 2, the focus of this session will be on providing specific guidelines in administering the SCERTS Assessment Process (SAP). The SAP is a curriculum-based assessment designed to pinpoint next steps in the domains of Social Communication (SC) and Emotional Regulation (ER), the core challenges in students with ASD. The SAP is designed to: 1) establish a profile of developmental strengths and needs, 2) to write meaningful educational goals based on a student's profile, functional needs, and preferences, 3) to select the most relevant interpersonal and learning supports, and 5) to monitor progress over time.",
          },
        ],
      },
      {
        title:
          "Overview of Socially Savvy Curriculum and Skillstreaming Curriculum",
        new: true,
        category: "professional-development",
        route: "socially-savvy-and-skillstreaming-curriculum",
        length: "1 Day",
        participants: {
          header: "",
          list: [
            "SLPs",
            "Counselors",
            "Social Workers",
            "Teachers (General and Special Education)",
            "Administrators",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "Participants will learn about two social skill curriculums that are currently on the market that have been used extensively with students with ASD of all ages.",
          },
          {
            type: "paragraph",
            value:
              "One curriculum that will be discussed is Socially Savvy Curriculum (Ellis, J. T., and Almeida, C., 2014) which is designed to help educators and parents break down broad areas of social functioning into concrete skills. The included checklist pinpoints a child's specific strengths and challenges which in turn makes it possible to prioritize the skills most in need of intervention, develop strategies to address them, and track the effectiveness of those strategies. The Socially Savvy Online Checklist, a companion to the Socially Savvy Curriculum will also be discussed.",
          },
          {
            type: "paragraph",
            value:
              "The second curriculum that will be discussed in this session is Skillstreaming (McInnis, E. and Goldstein, A. P. ) is a highly acclaimed, evidence-based prosocial skills training program that builds social-emotional competence. It employs a four-part training approach—modeling, role-playing, performance feedback, and generalization—to teach essential prosocial skills to children and adolescents. Each book provides a complete description of the Skillstreaming program, with instructions for teaching a wide variety of prosocial skills.  The Skillstreaming program is designed for preschool, elementary and secondary students.",
          },
        ],
      },
      {
        title: "Student Specific Evaluations",
        new: true,
        category: "professional-development",
        route: "student-specific-evals",
        length: "Varying",
        participants: {
          header: "",
          list: [
            "School-district personnel assigned to conduct an assessment on a particular student",
          ],
        },
        content: [
          {
            type: "paragraph",
            value:
              "CARE BH consultants have received extensive training on how to conduct comprehensive team-based evaluations for students suspected of having a disability. A student specific evaluation can be conducted with school-district evaluation personnel with the coaching and support of a CARE BH Consultant",
          },
          {
            type: "bullets",
            value: {
              header: "CARE BH can facilitate how to",
              numbered: false,
              list: [
                "develop an evaluation plan including the appropriate instruments to use to conduct behavioral observations across settings and interviews",
                "interpret the test results",
                "write a comprehensive team-based report",
                "develop evidenced-based recommendations plan for intervention in the classroom",
              ],
            },
          },
        ],
        lengthNote:
          "The length of time needed will be dependent on whether CARE BH personnel are completing the entire evaluation or facilitating the student specific evaluation with school district team members",
      },
    ],
    // otherOfferings: [],
  };

export const ConsultationServices: ConsultationServices = {
  districtServices: [
    {
      title: "Hands on Training: Principles of Applied Behavior Analysis (ABA)",
      category: "consultations",
      route: "hands-on-principles-ABA",
      content: [
        {
          type: "paragraph",
          value:
            "CARE BH provides intensive, evidence-based training in the principles of Applied Behavior Analysis (ABA). The goal of the training is to give teachers and others strategies needed to create positive learning environments for students with ASD using the research based principles of ABA. Dr. Schaefer, BCBA-D, LBA, will serve as the lead trainer and provide the didactic lectures that will introduce various principles of ABA as well as evidenced-based strategies and practices that have been identified for students with ASD.",
        },
        {
          type: "bullets",
          value: {
            header: "Training Goals",
            numbered: false,
            list: [
              "To introduce basic behavioral principles (prompting, shaping, reinforcement, task analyses, pairing, forward and backward chaining, etc) that staff can utilize in their classrooms to meet the individualized needs of students with ASD and related disorders.",
              "To become proficient in the use of discrete trial instruction (DTI) that is a teaching methodology within Applied Behavior Analysis (ABA) with a focus on beginning stages of instruction of learning to learn prerequisite skills (compliance, attending, imitating, matching, and functional communication skills).",
            ],
          },
        },
        {
          type: "bullets",
          value: {
            header: "Training Format",
            numbered: false,
            list: [
              "Mixture of didactic lecture and hands-on training with students with Autism Spectrum Disorder and related disorders",
              "Use of Behavioral Skills Training (BST) to teach participants how to apply principles of ABA in the school setting with students with ASD and related disorders.",
              "This is a 4-5 day training with trainers who have extensive experience working with students with ASD in preschool, elementary, and secondary settings.",
            ],
          },
        },
      ],
    },
    {
      title: "How to Conduct Functional Behavior Assessments (FBAs)",
      category: "consultations",
      route: "FBA",
      content: [
        {
          type: "paragraph",
          value:
            "This training is designed to teach your staff how to conduct Functional Behavior Assessments (FBAs). CARE BH consultants will instruct and model for your staff how to gather pertinent information during the FBA process using direct and indirect assessment methods including how to conduct interviews with staff members, parents and others knowledgeable of the student. CARE BH consultants will take your staff through a step-by-step process to determine if the results of the FBA warrants the development of BIP to address problem behaviors being exhibited by the student. Alternatives to a BIP may include the development of strategies and interventions that can be used to address the student's problem behavior and/or how to identify and develop goals and objectives to address skill deficits identified during the FBA process.",
        },
        {
          type: "paragraph",
          value:
            "CARE BH Consultants are also available to conduct or facilitate an FBA for a student that needs to have an FBA conducted as a result of a request for an IEE or when the district is seeking additional support when interventions have not been successful in addressing problem behavior.",
        },
      ],
    },
    {
      title:
        "How to Develop, Implement, Monitor and Evaluate Behavior Intervention Plans (BIPs)",
      category: "consultations",
      route: "BIP",
      content: [
        {
          type: "paragraph",
          value:
            "This training is designed to teach your staff how to write efficient and effective Behavior Intervention Plans (BIPs). CARE BH consultants will instruct your staff on how to select evidence-based, data-driven intervention techniques that promote student success.",
        },
        {
          type: "bullets",
          value: {
            header:
              "Our Consultants will work directly with your staff members to",
            numbered: false,
            list: [
              "Facilitate the development of a BIP with identified team members.",
              "Use information gathered during the FBA process to develop a Behavior Intervention Plan.",
              "Identify preventative measures that will serve to decrease the likelihood that the problem behavior will occur. This may include various environmental, academic and behavioral supports.",
              "Identify replacement behaviors that serve the functional equivalent to the problem behavior.",
              "Identify a step-by-step plan for teaching the replacement behavior to the student across settings which may include the identification of specific behavior analytic procedures.",
              "Develop appropriate consequences that will effectively reinforce the use of the newly taught behavior and reduce the likelihood that the student will engage in the problematic behavior.",
              "Develop a simple data collection system for ongoing monitoring of BIP goals and evaluation of BIP effectiveness.",
            ],
          },
        },
        {
          type: "paragraph",
          value:
            "CARE BH Consultants are also available to conduct or facilitate the development of BIP for a student that needs to have an BIP developed as a result of a request for an IEE or when the district is seeking additional support when interventions have not been successful in addressing problem behavior.",
        },
      ],
    },
    // {
    //   title: "Behavior Intervention Plans (BIPs)",
    //   category: "consultations",
    //   route: "BIP",
    //   content: [
    //     {
    //       type: "paragraph",
    //       value:
    //         "This training is designed to teach your staff how to write efficient and effective Behavior Intervention Plans (BIPs). CARE BH consultants will instruct your staff on how to select evidence-based, data-driven intervention techniques that promote student success.",
    //     },
    //     {
    //       type: "bullets",
    //       value: {
    //         header: "Our Consultants will Work with Staff Members to",
    //         numbered: false,
    //         list: [
    //           "Facilitate the development of a BIP with various team members.",
    //           "Use information gathered during the FBA process to develop a Behavior Intervention Plan.",
    //           "Identify preventative measures that will serve to decrease the likelihood that the problem behavior will occur. This may include various environmental, academic and behavioral supports.",
    //           "Identify replacement behaviors that serve the functional equivalent to the problem behavior.",
    //           "Identify a step-by-step plan for teaching the replacement behavior to the student across settings which may include the identification of specific behavior analytic procedures.",
    //           "Develop appropriate consequences that will effectively reinforce the use of the newly taught behavior and reduce the likelihood that the student will engage in the problematic behavior.",
    //           "Develop a simple data collection system for ongoing monitoring of BIP goals and evaluation of BIP effectiveness.",
    //         ],
    //       },
    //     },
    //     {
    //       type: "paragraph",
    //       value:
    //         "CARE BH Consultants are also available to conduct or facilitate the development of BIP for a student that needs to have an BIP developed as a result of a request for an IEE or when the district is seeking additional support when interventions have not been successful in addressing problem behavior.",
    //     },
    //   ],
    // },
    {
      title:
        "Developing Strategies and Plans for Working Effectively with Students Who Are Exhibiting Significant Challenging Behaviors in the School Setting",
      category: "consultations",
      route: "strategies-and-plans-for-challenging-behaviors",
      content: [
        {
          type: "paragraph",
          value:
            "School personnel are facing an increasing number of students with and without disabilities who are exhibiting significant challenging behaviors in the public school setting. This session will cover proactive strategies for dealing effectively with students who persistently engage in problem behavior that interferes in their learning or the learning of others. Participants will learn how to conduct Functional Behavior Assessments (FBAs), develop, implement, monitor and evaluate Behavior Intervention Plans (BIPs) that teach students new ways to behave using socially acceptable behaviors and allow them to remain in the public school setting. The session will also cover how to develop and implement crisis intervention plans when all other steps of the BIP have been exhausted. Legal implications and responsibilities of school personnel will also be discussed.",
        },
      ],
      length: "1 Day",
      participants: {
        header: "Evaluation Personnel",
        list: [
          "School Psychologists",
          "Educational Diagnosticians",
          "School Counselors",
          "Social Workers",
          "SLPs",
          "OTs",
          "Behavior Specialists",
          "Teachers",
          "Administrators",
        ],
      },
    },
    {
      title:
        "Functional Approach to Problem Behavior: Prevent Teach Reinforce (PTR)",
      category: "consultations",
      route: "PTR",
      content: [
        {
          type: "paragraph",
          value:
            "Many students with ASD and related disorders often exhibit a high level of problem behavior across settings that they participate in. A systematic approach to addressing problem behavior in the public school setting is needed so that students can reduce severe challenging behaviors and learn more desirable behaviors leading to improvements in their overall social and academic outcomes. One of the six core elements of educating students with Autism Spectrum Disorder (ASD) regardless of placement is Functional Approach to Problem Behavior which include: Positive Behavior Supports (PBS) and/or Applied Behavior Analysis (ABA).",
        },
        //potential bulleting for list on this content
        {
          type: "paragraph",
          value:
            "The presenter will provide an overview of an evidenced-based approach for conducting Functional Behavior Assessments (FBAs) and then developing and implementing Behavior Intervention Plans (BIPs) to solve behavior challenges in K-8 classrooms using the Prevent-Teach-Reinforce (PTR) model which was developed by a team of experts who developed this research-based approach. This session will provide participants with a step-by-step model that your school team can use for reducing problem behaviors that are not resolved by the use of typical behavior management strategies utilized by classroom teachers. This session will provide participants with guidance on how to implement all five steps of the PTR process which includes: 1) Teaming and goal-setting, 2) Collecting data, 3) Conducting a PTR Functional Behavioral Assessment, 4) Developing a PTR Behavior Intervention Plan, and 5) Monitoring progress and making data-based decisions. This approach can also be used as part of a Multi-Tiered Systems of Support (MTSS) for typically developing students and/or other students with disabilities who are exhibiting problem behavior.",
        },
        {
          type: "paragraph",
          value:
            "The workshop will also introduce participants to the PTR model that has been developed for young children as well as for use with families in the home setting.",
        },
      ],
      length: "1 Day",
      participants: {
        header: "",
        list: [
          //can likely shorten this but currently copying from Jan
          "School Psychologists, Counselors, Social Workers, Behavior Specialists, BCBAs, etc who typically deal with severe problem behavior on their campus and/or are in charge of conducting and/or developing FBAs and BIPs for students with challenging behaviors",
          "Educators and others (counselors, social workers, related service personnel, etc who typically implement FBAs and BIPs would also benefit from learning how the PTR model is intended to be implemented, monitored, and evaluated with their assigned students)",
        ],
      },
    },
    {
      title: "Collaborative Technical Assistance and Support (CTAS) Model",
      category: "consultations",
      route: "CTAS",
      content: [
        {
          type: "header",
          value: "What is the CTAS Model?",
        },
        {
          type: "paragraph",
          value:
            "The Collaborative Technical Assistance and Support (CTAS) Model was designed by CARE BH Consultants after reviewing the federal “scaling-up” literature along with the definitions and descriptions provided by the U.S. Department of Education Office of Special Education Programs for what constitutes intensive technical assistance and basic technical assistance.",
        },
        {
          type: "paragraph",
          value:
            "Using the federal definition as a foundation, the CTAS model was designed to ensure that technical assistance and support would be provided with a clearly defined purpose and set of outcomes. CARE BH consultants ensure careful attention and planning is utilized to determine how those outcomes can be most efficiently achieved.",
        },
        {
          type: "paragraph",
          value:
            "Although basic technical assistance (traditional training and support) is effective in many contexts, it may not be sufficient to achieve sustainable classroom/organization/ systemic changes in other contexts. CTAS is a multi-pronged, long-term strategy to address this.",
        },
        {
          type: "paragraph",
          value:
            "The CTAS model includes a purposeful, planned series of activities designed to reach an outcome that is valued by the school district. Intensive Technical Assistance (ITA) provided through the CTAS model typically results in changes to policy, program, practice, or operations that support increased recipient capacity and/or improved outcomes at one or more systems levels.  This is typically a multi-year approach that includes the use of ongoing evaluation and feedback strategies.",
        },
        // {
        //   type: "paragraph",
        //   value:
        //     "This is typically a multi-year approach that includes the use of ongoing evaluation and feedback strategies.",
        // },
        // {
        //   type: "paragraph",
        //   value:
        //     "The CARE BH approach to CTAS allows us to divide the types of training we do into basic technical assistance versus intensive technical assistance activities through the CTAS model. This results in systems change and building capacity within an organization such as a school district.",
        // },
        { type: "header", value: "Why Implement the CTAS Model?" },
        {
          type: "paragraph",
          value:
            "Our professionals recognize that there are times when there are issues encountered in education that can be solved by providing basic technical assistance via information and support to already knowledgeable and skilled teachers and administrators. Recipients of this type of support take responsibility for making good and effective use of the information and training they receive through workshops and other professional development activities designed to enhance the knowledge of those participants. There is also a recognition that there are other times when the staff members would benefit from Intensive Technical Assistance (ITA) that require an ongoing negotiated relationship with clearly defined goals and objectives between CARE BH and the school district receiving assistance.",
        },
        { type: "header", value: "Is the CTAS Model Effective?" },
        {
          type: "paragraph",
          value:
            "Recently, the CTAS model was recognized as one of the Texas Educational Agency (TEA) Innovative Projects during the 2022-2023 school year. CARE BH consultants implemented the CTAS model and provided intensive technical assistance to five school districts in South Texas who were involved in the project. The CTAS model was also used as part of a TEA Autism Grant: Year 2 that was funded for the 2023-2024 school year with an additional two school districts in South Texas.",
        },
        // {
        //   type: "bullets",
        //   value: {
        //     header: "",
        //     numbered: false,
        //     list: [
        //       "Office of Special Education Program's (OSEP's) recommendation: \"to provide purposeful, planned series of activities designed to reach an outcome that is valued by the host organization\" (June, 2009).",
        //       'National Implementation Research Network (Fixsen et al, 2005) concluded: "there is good evidence that successful implementation efforts require a longer-term multilevel approach."',
        //     ],
        //   },
        // },
        {
          type: "paragraph",
          value:
            "CARE BH's entire multidisciplinary team participates in Collaborative Technical Assistance and Support (CTAS) with individuals, families, schools and organizations across the US. Recently, the CTAS model was identified as one of the Texas Educational Agency (TEA) Innovative Projects.",
        },
        // {
        //   type: "paragraph",
        //   value:
        //     "CARE BH's entire multidisciplinary team participates in Collaborative Technical Assistance and Support (CTAS) with individuals, families, schools and organizations across the US. Recently, the CTAS model was identified as one of the Texas Educational Agency (TEA) Innovative Projects. CARE BH consultants implemented the CTAS model with 5 school districts in South Texas who were involved in the project during the 2022-2023 school year. The CTAS model is currently being used as part of a TEA Autism Grant: Year 2 that was funded for the 2023-2024 school year with two school districts in South Texas.",
        // },
      ],
    },
    {
      title:
        "Effective Educational Practices for Students with Autism Spectrum Disorder Across the Continuum",
      category: "consultations",
      route: "educational-practices",
      length: "1 Day",
      participants: {
        header: "",
        list: [
          "General and special education teachers",
          "Paraprofessionals",
          "Related service personnel",
          "Administrators",
        ],
      },
      content: [
        {
          type: "paragraph",
          value:
            "This workshop will cover the six core elements that have empirical support and should be part of any sound, comprehensive instructional educational program for students with Autism Spectrum Disorder.",
        },
        {
          type: "paragraph",
          value:
            "Participants will be able to describe the six core elements that should be considered as part of effective educational programming for students with ASD and related disorders:",
        },
        {
          type: "bullets",
          value: {
            header: "",
            // "Participants will be able to describe the six core elements that should be considered as part of effective educational programming for students with ASDs and related disorders",
            numbered: true,
            list: [
              "Individualized Supports and Services for Students and Families",
              "Systematic Instruction",
              "Comprehensible/Structured Learning Environments",
              "Specialized Curriculum Content",
              "Functional Approach to Problem Behavior",
              "Family Involvement",
            ],
          },
        },
        {
          type: "paragraph",
          value:
            "Participants will complete Professional Development Needs Checklist to help them identify professional development needs that will support their understanding of the six core elements",
        },
        {
          type: "paragraph",
          value:
            "Participants will be introduced to Effective Practices Classroom Rubric and how this tool can be utilized to enhance effective educational programming for their students with ASD during the upcoming school year.",
        },
        {
          type: "paragraph",
          value:
            "Participants will be provided with online resources focused on evidenced-based practices that are free and can be accessed throughout the school year",
        },
      ],
    },
    {
      title: "Facilitating Parent Caregiver Trainings and Support Groups",
      category: "consultations",
      route: "facilitating-parent-caregiver-trainings",
      content: [
        {
          type: "header",
          value: "Preparing for Parent Caregiver Trainings and Meetings",
        },
        {
          type: "paragraph",
          value:
            "CARE BH consultants provide support to the school district during parent/caregivers meetings by providing training to staff on research-based, best practice interventions and data collection procedures that will provide parents with the information needed to ensure their student is in a productive and nurturing environment. Our consultants will facilitate the development and implementation of parent/caregiver training provided by personnel who are knowledgeable about topics that are relevant to parents in Spanish and/or in English. They will also model for staff members how to develop and conduct effective parent meetings and support groups within the public school system.",
        },
        {
          type: "header",
          value: "Parent/Caregiver Trainings and Meetings",
        },
        {
          type: "paragraph",
          value:
            "CARE BH consultants have developed training to address the needs of families who have children with Autism and related disorders; families and caregivers who have children that exhibit challenging behaviors in the home and community settings. Parent trainings are developed for school districts by conducting needs assessments and/or conducting focus groups with the families and then working with them to develop a priority for training topics Parent training can be conducted in a variety of formats including online, face-to-face and hybrid options.",
        },
      ],
    },
    {
      title: "Conducting Program Evaluations",
      category: "consultations",
      route: "program-evals",
      content: [
        {
          type: "paragraph",
          value:
            "Program Evaluations are conducted by CARE BH consultants who have extensive experience working in public school settings and who are knowledgeable of evidenced-based practices related to programming for students who are identified under various disability or eligibility categories. These evaluations utilize various methods including interview of staff members, administrators (building and district level), parents, students and other stakeholders; observation of students in a variety of settings across campuses; surveys of staff members; administrators; parents; students and other stakeholders; review of records including IEPs and other sources of data pertinent to the program evaluation. Data accumulated from various sources is assimilated into a comprehensive report with strengths and weaknesses of the program identified when compared to evidenced-based practices along with specific recommendations. An executive summary is also created summarizing key aspects of the program evaluation with recommendations for consideration by stakeholders. The comprehensive report and/or executive summary is typically presented to key personnel by the lead consultant assigned to coordinate the activities of the program evaluation.",
        },
        {
          type: "compoundBullets",
          value: {
            header:
              "Types of Program Evaluations Currently Conducted by CARE BH",
            list: [
              {
                header: "Inclusive Education Practices:",
                list: [
                  "Early Childhood",
                  "Elementary levels",
                  "Secondary Levels",
                ],
              },
              {
                header: "Programs Designed for Specific Populations:",
                list: [
                  "Early Childhood Education (ECE)/Early Childhood Special Education (ECSE)",
                  "Students with Autism Spectrum Disorder (ASD)",
                  "Students with Intellectual Disabilities (ID)",
                  "Secondary Students Who Are Transitioning to Post-High School Activities",
                  "Students with Emotional Disturbance (ED)/Behavioral Disturbance (BD)",
                ],
              },
              {
                header: "Program Evaluations Can Also Be Conducted for:",
                list: [
                  "Special Education Departments",
                  "Staffing of Special Education Personnel",
                  "Development, Implementation and Monitoring of IEPs/ARDs",
                  "FBAs and/or BIPs Utilizing the Technical Adequacy Tool for Evaluation (TATE)",
                  "Assessment and Evaluation Practices",
                ],
              },
            ],
          },
        },
        {
          type: "paragraph",
          value:
            "These evaluations are meant to consider the need for change in programs on a variety of levels within a school system. CARE BH consultants are highly trained in providing these services for the entire district*, one or more specified campuses within the district or for an individual program.",
        },
        {
          type: "header",
          value: "Why Conduct a Program Evaluation In Your District?",
        },
        {
          type: "bullets",
          value: {
            header:
              "Program Evaluations are Intended to Support a Process that Includes",
            numbered: false,
            list: [
              "Assessing the quality of a school district's instructional programs and practices in the areas of behavioral supports and interventions literacy, and delivery of special education services",
              "Determining priority need areas",
              "Prescribing and planning activities to change practices and improve outcomes for students with disabilities",
            ],
          },
        },
        {
          type: "paragraph", //TODO: TURN THIS INTO A NOTE AND FORMAT NOTES
          value:
            "District-wide support usually requires Intensive Technical Assistance (ITA) and may involve a multi-year approach in order to build capacity and achieve outcomes identified by school district personnel.",
        },
      ],
    },
    //TODO: ARE WE REMOVING THIS PAGE?
    {
      title: "Student Specific Evaluations",
      category: "consultations",
      route: "student-specific-evals",
      content: [
        {
          type: "paragraph",
          value:
            "CARE BH consultants have received extensive training on how to conduct comprehensive team-based evaluations for students suspected of having a disability. This can occur using basic technical assistance through professional development training designed to increase the knowledge of professionals assigned to conduct team-based assessments or who have been identified to conduct assessments for students suspected of having ASD. Our team provides training on how to:",
        },
        {
          type: "bullets",
          value: {
            header: "",
            numbered: false,
            list: [
              "develop an evaluation plan",
              "choose which instruments to use",
              "interpret the test results",
              "write a comprehensive team-based report with recommendations",
              "develop a plan for implementation in the classroom",
            ],
          },
        },
        {
          type: "paragraph",
          value:
            "CARE BH Consultants are also available to conduct IEEs and/or conduct FBAs and BIPS for students with challenging behaviors.",
        },
        {
          type: "paragraph",
          value:
            "Contact us today so we can tailor an evaluation to meet your district's needs.",
        },
      ],
    },
  ],
  inclusiveEducationPractices: [
    {
      title: "Inclusive Education Practices",
      category: "consultations",
      route: "inclusive-education",
      content: [
        {
          type: "paragraph",
          value:
            "CARE BH provides consultative services to school districts to identify and implement best practices for inclusive education.",
        },
        {
          type: "paragraph",
          value:
            "CARE BH will train personnel to recognize, understand, access, and implement the full range of services available to individuals with disabilities. Our consultants will also focus on a broad range of common issues ranging from academic and social exclusion to challenges students experience interacting with peers with disabilities, and the impact of inclusive practice on schools, families and communities. In order for this to be successfully accomplished, general education and special education staff as well as campus administrators will benefit from site-based training in the following areas to increase their capacity to serve all students. Follow along support in the form of technical assistance can also be provided to campus personnel once they have participated in the site-base training. CARE BH's team includes leaders in the field of inclusive education in helping with systems change to provide technical assistance and support throughout the US (and the world). Inclusive Education is a philosophy and approach, where:",
        },
        {
          type: "bullets",
          value: {
            header: "",
            numbered: false,
            list: [
              "All students are educated in the general education classroom to the greatest extent appropriate",
              "Expectations are high",
              "Instruction is standards-based",
              "The curriculum is the general education curriculum",
              "Individualized supports are available when needed",
              "Decisions are made on the basis of student needs and not labels and places",
            ],
          },
        },
        { type: "header", value: "Why Embrace Inclusive Education?" },
        {
          type: "bullets",
          value: {
            header:
              "National Longitudinal Transition Study (2006) showed that more time in General Education classes was positively correlated with",
            numbered: false,
            list: [
              "higher scores on standardized tests of math and reading",
              "fewer referrals for disruptive behavior",
              "fewer absences",
            ],
          },
        },
        {
          type: "paragraph",
          value:
            "No studies since the 1970s have shown an academic advantage for students with intellectual and other developmental disabilities educated in separate settings (Falvey, 2004)",
        },
      ],
    },
    {
      title: "Inclusive Practices Model Training - Ongoing Technical Support",
      category: "consultations",
      route: "inclusive-practices-ongoing-support",
      content: [
        {
          type: "paragraph",
          value:
            "State and federal laws increasingly require schools to implement inclusive environments to create a system of shared ownership and accountability for all students. The initial 2.5-day training provided by CARE BH will carefully examine the four major components stakeholders need to create operable, successful inclusive environments. Twentieth Century schools reflect the diversity of the society they serve. Increasingly, schools are finding it necessary to restructure their efforts to provide effective services for ALL students in an inclusive setting. In this seminar, trainers will discuss the following topics: effective staffing, changing systems and the law, aligning instruction, and the means for increasing collaboration. The last half day of the training will be dedicated to 1) developing a concise yet comprehensive assessment of individual school practices as they relate to Inclusive Education and 2) creating a 'needs assessment' and 'action plan' for future growth.",
        },
        {
          type: "paragraph",
          value:
            "We recommend targeted technical support for all school teams that have attended this training and implemented the Inclusive model. Year-1 support will consist of 3 on-site technical support days. Year-2 will consist of 2 targeted, on site days of technical support. Schools can request additional technical assistance, as needed or desired.",
        },
      ],
    },
    {
      title: "Co-Teaching in the Inclusive Setting",
      category: "consultations",
      route: "inclusive-co-teaching",
      content: [
        {
          type: "paragraph",
          value:
            "Inclusion is a belief system that values diversity and fosters a shared responsibility to help all students reach their maximum potential. As schools increasingly implement inclusive educational settings, instructional teams and staff members are adjusting their professional practices to accommodate various types of learners in the same general education classrooms. The co-teach model is a highly effective system that allows general and special educators to differentiate and deliver instruction with a reasonable assurance that all students will receive full access to grade-level expectations in the general education setting. We propose conducting a 2-day, comprehensive training that will establish a common understanding of the co-teach model, discuss the various co-teaching configurations, and explain the tools necessary to implement and evaluate an effective co-teaching program.",
        },
      ],
    },
    {
      title: "Facilitating IEPs",
      category: "consultations",
      route: "IEP",
      content: [
        {
          type: "paragraph",
          value:
            "CARE BH consultants provide training to teachers, administrators and others involved in the IEP development, implementation and ongoing progress monitoring using a systematic approach that ensure that all staff are conducting assessments (such as state assessments, teacher-made tests, multiple choice tests, work samples, observations, progress reports, curriculum-based measures, rubrics, parent information, observations, state assessment confidential student reports, etc.) that lead to establishing a baseline and a summary addressing strengths and areas of concerns that lead to the development of Present Levels of Academic Achievement and Functional Performance (PLAAFPs).",
        },
        {
          type: "paragraph",
          value:
            "Our team will share how to develop measurable goals and objectives based on state standards from the PLAAFPs and use ongoing progress monitoring to determine whether adequate progress is being made on the goals and objectives. The CARE BH team will also demonstrate how to ensure that the IEP team members can addresses how the student's disability impacts involvement/progress in the general education setting, as well as its impact on functioning in everyday life; address the need for accommodations/modifications; and provide a description of academic, functional, and transition needs.",
        },
        {
          type: "paragraph",
          value:
            "CARE BH consultants can also provide training on how to utilize IEP rubrics across the district so that as IEPs are being completed they are being reviewed to determine if they meet technical adequacy. The use of rubrics provides a consistent and objective way to review IEPs and determine where additional training and technical support may be needed by different teams across the district so that targeted support can be provided and/or feedback on how to improve the technical adequacy of IEPs by various teams and/or individuals.",
        },
      ],
    },
    {
      title:
        "Understanding and Implementing Accommodations and Modifications for the Student with Disabilities",
      category: "consultations",
      route: "understanding-and-implementing-accommodations",
      content: [
        {
          type: "paragraph",
          value:
            "This hands-on and interactive training will discuss the important differences between accommodations and modifications to curriculum for students with disabilities. CARE BH consultants will provide and highlight specific examples of differentiating content to meet the needs of learners with diverse abilities.",
        },
      ],
    },
    {
      title: "Guiding the IEP Writing Process",
      category: "consultations",
      route: "IEP-written-guidance",
      content: [
        {
          type: "paragraph",
          value:
            "Federal guidelines governing the IEP process will be a key focus of this training. CARE BH consultants will guide educators and administrators through the maze of forms and processes involved in the IEP process, and prepare them to write an effective and legally binding IEP. Finally, CARE BH consultants will highlight the strategic importance of involving parents as active members of the IEP team.",
        },
      ],
    },
  ],
  otherTypesOfClassroomConsultativeSupport: [
    {
      title: "Classroom Support",
      category: "consultations",
      route: "type-classroom-support",
      content: [
        {
          type: "paragraph",
          value:
            "CARE BH professionals will work side-by-side with the classroom teachers to facilitate seamless application of interventions and methods taught in one of the CARE BH training sessions. These team teaching sessions are designed to provide a supportive and constructive work environment for the teacher. The CARE BH consultant will model trained methods and observe the instructor implementing those methods to ensure correct application. Our goal is to ensure your staff is ready to implement a learning environment that gets students excited for learning and fosters success!",
        },
      ],
    },
    {
      title: "Classroom Designs That Are Visibly Comprehensible",
      category: "consultations",
      route: "type-classroom-designs",
      content: [
        {
          type: "paragraph",
          value:
            "Your staff just received a CARE BH training which presented new and innovative ideas on classroom design techniques that foster student success! Now what? CARE BH is available to assist your staff in designing their classroom! Our professionals are highly experienced in working with spaces from small classrooms to vast life skills settings while ensuring the most effective instructional environment is available to your students. Our professionals will work side-by-side with your staff to ensure they make the most of the space they have for instruction. CARE BH will consider the student population, subject matter, IEP goals and much more!",
        },
      ],
    },
    {
      title: "Functional Communication Training (FCT) for Classroom Staff",
      category: "consultations",
      route: "type-FCT",
      content: [
        {
          type: "paragraph",
          value:
            "CARE BH Consultants can provide training on how to increase the functional communication skills of students so that they can learn how to get their basic wants and needs met. When students who are low verbal or non-verbal are taught a more effective way to communicate with others, they are less likely to engage in problematic behaviors.",
        },
        {
          type: "paragraph",
          value:
            "CARE BH Consultants will model for staff how to engage students so that they are motivated to use the Picture Exchange Communication System (PECS) or a communication device (AAC or SGD) to gain access to the highly reinforcing items. It has been our experience that staff benefit from modeling and hands-on training in the classroom setting on how to increase the functional communication skills of students on their caseload. Opportunities for teaching functional communication skills can be embedded to all aspects of the instructional program and should be a primary goal for staff working with students ages 3-8 and/or with students who are low verbal or nonverbal and have not established an effective way to communicate with others.",
        },
      ],
    },
    {
      title: "Social Skills Training for Classroom Staff",
      category: "consultations",
      route: "type-social-skills-training",
      content: [
        {
          type: "paragraph",
          value:
            "CARE BH Consultants have developed various training components related to increasing the social skills of students having difficulty making friends, participating in group activities and games, demonstrating age-appropriate play behaviors and engaging in prosocial behavior. Training in peer-mediated instruction and other established treatments can be provided. Students who are more socially competent are afforded more opportunities to participate in the Least Restrictive Environment (LRE) with general education peers.",
        },
        {
          type: "paragraph",
          value:
            "CARE BH Consultants have also worked with school districts to develop different models of social skills training that fit the needs of the individual district. For instance, after school social skills groups, play groups, social skill classes, and individualized counseling sessions, and how to teach social skills contextually.",
        },
      ],
    },
    {
      title: "Use of Visual Supports Across the School Environment",
      category: "consultations",
      route: "type-visual-supports",
      content: [
        {
          type: "paragraph",
          value:
            "CARE BH Consultants can provide training on how to use visual supports in the classroom and across settings to increase predictability and teach routines to students with limited communication skills. Visual supports have been shown effective in increasing the overall level of independence of students with disabilities.",
        },
        {
          type: "paragraph",
          value:
            "CARE BH Consultants typically provide an overview of the different types of visual supports that can be developed and used with students with disabilities and then consultants will work with the classroom team to model how to implement them in the classroom setting to promote access to the general education curriculum, modify and adapt curriculum to meet the diverse needs of learners, and help students make sense of their environment.",
        },
      ],
    },
    {
      title: "Paraprofessional Training",
      category: "consultations",
      route: "type-paraprofessional-training",
      content: [
        {
          type: "paragraph",
          value:
            "This hands-on and interactive training will focus on the paraprofessional's requirement to facilitate student independence. The key audience will be teacher-paraprofessional teams: however, both teachers and paraprofessionals will individually benefit from the interactive discussion of strategies that scaffold student independence and self-advocacy skills. CARE BH consultants will highlight the role of the paraprofessional; teacher-paraprofessional relationship dynamics; guidelines for student and parent interaction; and useful student-focused, age-appropriate strategies and tools.",
        },
      ],
    },
    {
      title:
        "Conducting Functional Behavior Assessments (FBAs) in the Classroom Setting",
      category: "consultations",
      route: "type-conducting-FBAs-in-classroom",
      content: [
        {
          type: "paragraph",
          value:
            "Ever wonder, “Why is he doing that?” Do you want to better understand the function of a student's behavior? Let CARE BH help!",
        },
        {
          type: "paragraph",
          value:
            "CARE BH consultants will conduct or facilitate a team-based Functional Behavioral Assessment (FBA) process. The goal of the FBA is to identify and operationally define the problem or target behavior. The team then develops a hypothesis for the function of the target behavior and determines whether the target behavior is a skill (can't do) or performance (won't do) deficit. Team will also look at antecedents that may trigger the behavior as well as consequences that may serve to reinforce the behavior. The team will outline various strategies that have been tried with the student and then rate the level of effectiveness. The team will determine if through the FBA process enough information has been gathered to develop a Behavior Intervention Plan (BIP) or whether there is a need for more data.",
        },
        {
          type: "paragraph",
          value:
            "CARE BH's approach to conducting FBA and BIPs is to facilitate a process that involves parents and other team members who interact with the student or client on a regular and ongoing basis to develop and implement a plan that contains preventative measures, teaching of functional equivalent and/or replacement skills and that uses reinforcement that is likely to increase the student's use of the new skills being taught using evidenced-based practices in a very systematic and highly consistent manner.",
        },
      ],
    },
    {
      title: "Positive Behavior Support (PBS)",
      category: "consultations",
      route: "type-PBS",
      content: [
        {
          type: "paragraph",
          value:
            "PBS is a set of research-based strategies used to increase quality of life and decrease problem behavior by teaching new skills and making changes in a student's environment.",
        },
        {
          type: "paragraph",
          value:
            "Consultants from CARE BH will train district administration and staff on PBS principles and techniques that can be utilized throughout both their classroom set-up and academic lessons. Research has shown that the continued implementation of PBS techniques in the classroom improves student behavior, academic performance, and motivation to succeed.",
        },
      ],
    },
    {
      title: "School-wide Positive Behavior Support (SW-PBS)",
      category: "consultations",
      route: "type-SW-PBS",
      content: [
        {
          type: "paragraph",
          value:
            "School-wide Positive Behavior Support (SW-PBS) is an additional training CARE BH professionals offer for specific campuses over a specified timeframe.",
        },
        {
          type: "paragraph",
          value:
            "PBS also promotes staff members working as a team to discuss interventions and successes. Team building among staff members is an integral part to making PBS as successful as possible in the public-school setting.",
        },
      ],
    },
  ],
};
