<template>
  <div ref="searchContainer" id="search-container">
    <input
      id="search-input"
      placeholder="search our services"
      type="text"
      v-model="searchValue"
    />
    <div
      id="search-prompt-results"
      class="flex column back-blue text-white"
      v-if="showSearch && searchPromptResults.length"
    >
      <div
        class="search-result-item"
        :key="serviceIndex"
        v-for="(service, serviceIndex) in searchPromptResults"
        @click="navigateToService(service.category, service.route)"
      >
        {{ service.title }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { CAREService } from "@/constants/services";
import {
  ConsultationServices,
  ProfessionalDevelopmentServices,
} from "@/constants/services";
import router from "@/router";
export default defineComponent({
  name: "NavSearch",
  data() {
    return {
      showSearch: false,
      searchValue: "",
      allServices: [
        ...ConsultationServices.districtServices,
        ...ConsultationServices.otherTypesOfClassroomConsultativeSupport,
        // ...ConsultationServices.otherDistrictLevelOfferings,
        ...ConsultationServices.inclusiveEducationPractices,
        ...ProfessionalDevelopmentServices.trainingForEvaluationPersonnel,
        // ...ProfessionalDevelopmentServices.otherOfferings,
      ] as CAREService[],
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event: MouseEvent) {
      if (
        this.$refs.searchContainer &&
        !(this.$refs.searchContainer as HTMLElement).contains(
          event.target as Node
        )
      ) {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
    },
    resetSearch() {
      this.searchValue = "";
      this.showSearch = false;
    },
    navigateToService(category: string, route: string) {
      router.push({ path: `/services/${category}/${route}` });
      this.resetSearch();
    },
  },
  computed: {
    searchPromptResults() {
      if (this.searchValue.length > 2) {
        const searchResults: CAREService[] = this.allServices.filter(
          (service: CAREService) => {
            return service.title
              .toLocaleLowerCase()
              .includes(this.searchValue.toLocaleLowerCase());
          }
        );
        return searchResults;
      } else {
        return [];
      }
    },
  },
  watch: {
    searchValue() {
      if (this.searchValue.length > 3) {
        this.showSearch = true;
      }
    },
  },
});
</script>
<style scoped>
#search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto 1em;
  height: 21px;
  width: 225px;
  overflow: visible;
}

#search-input {
  z-index: 10;
  width: 200px;
}
#search-prompt-results {
  position: absolute;
  top: 60px;
  width: 500px;
  overflow-x: hidden;
  border: 1px solid var(--care-blue-hover);
  /* FOR POTENTIAL MAX HEIGHT ON SEARCH. WOULD REQUIRE SOME INDICATOR THAT THE LIST GOES FURTHER */
  /* overflow-y: scroll;
  max-height: 200px; */
}
.search-result-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  cursor: pointer;
}
.search-result-item:hover {
  background-color: var(--care-blue-hover);
}
@media (max-width: 900px) {
  #search-container {
    display: none;
  }
}
</style>
