<template>
  <div id="homepage-testimonials" class="flex column align-c">
    <div
      id="testimonials-header"
      class="flex j-between align-c w-100 section-max-w"
    >
      <div class="static-large mr-auto">From our clients</div>
      <!-- <div class="midsize-text ml-auto text-link-basic">
        see more <span class="no-wrap-text">reviews&nbsp;></span>
      </div> -->
    </div>
    <div id="testimonials-container" class="flex w-100 section-max-w">
      <div class="testimonial-container">
        <div>
          "Thank you so much for the information on ADOS-2. It is exactly what I
          needed."<br />Your help is greatly appreciated"
        </div>
        <div class="testimonial-attribution">
          <br />- School Psychologist,<br />Nevada
        </div>
      </div>
      <div class="testimonial-divider" />
      <div class="testimonial-container">
        <div>
          "Your training for paraprofessionals was great. This is a training our
          entire district needs"
        </div>
        <div class="testimonial-attribution">
          <br />- Middle School Principal,<br />New Mexico
        </div>
      </div>
      <div class="testimonial-divider" />
      <div class="testimonial-container">
        <div>
          "I liked how we learned something new everyday of the training and how
          it all fits together"
        </div>
        <div class="testimonial-attribution">
          <br />- Teacher,<br />New Mexico
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "HomeTestimonials",
});
</script>
<style scoped>
@media (min-width: 901px) {
  #homepage-testimonials {
    padding: 1em;
    background-color: var(--care-blue-primary);
  }
  #testimonials-header {
    color: white;
  }
  #testimonials-container {
    justify-content: space-evenly;
    color: white;
  }
  .testimonial-container {
    width: 25%;
    margin-top: 2em;
  }
  .testimonial-attribution {
    margin-left: auto;
    width: fit-content;
  }
  .testimonial-divider {
    width: 2px;
    height: 9em;
    background-color: white;
    margin-top: 2em;
  }
}
@media (max-width: 900px) {
  #homepage-testimonials {
    padding: 1em;
    background-color: white;
  }
  #testimonials-header {
    color: var(--care-blue-primary);
    margin: 1em 0;
  }
  #testimonials-container {
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    /* width: 90%; */
    max-width: 900px;
    justify-content: space-evenly;
    color: white;
  }
  .testimonial-container {
    width: 300px;
    margin: 1em auto;
    background-color: var(--care-blue-hover);
    padding: 16px;
  }
  .testimonial-attribution {
    margin-left: auto;
    width: fit-content;
  }
}
</style>
