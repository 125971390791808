import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c57dea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "bullet-content",
  class: "text-blue mlr-auto"
}
const _hoisted_2 = {
  key: 0,
  class: "static-medium mb-1"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.bulletData.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.bulletData.header) + ": ", 1))
      : _createCommentVNode("", true),
    (_ctx.bulletData.numbered)
      ? (_openBlock(), _createElementBlock("ol", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulletData.list, (item, itemIndex) => {
            return (_openBlock(), _createElementBlock("li", { key: itemIndex }, _toDisplayString(item), 1))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulletData.list, (item, itemIndex) => {
            return (_openBlock(), _createElementBlock("li", { key: itemIndex }, _toDisplayString(item), 1))
          }), 128))
        ]))
  ]))
}