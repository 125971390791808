import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-235f9a7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "bullet-content",
  class: "text-blue mlr-auto"
}
const _hoisted_2 = {
  key: 0,
  class: "static-medium mb-1"
}
const _hoisted_3 = { class: "compound-bullet-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.bulletData.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.bulletData.header) + ": ", 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulletData.list, (item, itemIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "compound-bullet-item",
        key: itemIndex
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.header), 1),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (bullet, bulletIndex) => {
            return (_openBlock(), _createElementBlock("li", { key: bulletIndex }, _toDisplayString(bullet), 1))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}