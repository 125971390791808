<template>
  <div
    id="homepage-icon-links"
    class="section-max-w flex align-c mlr-auto text-blue"
  >
    <router-link to="/resources" class="homepage-link-container">
      <div class="homepage-link-text midsize-text mlr-auto">
        Autism <span class="no-wrap-text">Resources&nbsp;></span>
      </div>
      <img
        class="homepage-icon mlr-auto"
        src="/icons/key-to-success-svgrepo-com.svg"
        alt="Key to success"
      />
    </router-link>
    <router-link to="/services" class="homepage-link-container">
      <div class="homepage-link-text midsize-text mlr-auto">
        How does CARE <span class="no-wrap-text">help?&nbsp;></span>
      </div>
      <img
        class="homepage-icon mlr-auto"
        src="/icons/team-success-svgrepo-com.svg"
        alt="Teamwork"
      />
    </router-link>
    <router-link to="/contact" class="homepage-link-container">
      <div class="homepage-link-text midsize-text mlr-auto">
        Need CARE <span class="no-wrap-text">services?&nbsp;></span>
      </div>
      <img
        class="homepage-icon mlr-auto"
        src="/icons/meeting-icon.svg"
        alt="Consultations"
      />
    </router-link>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "IconLinks",
});
</script>
<style scoped>
/* #homepage-icon-links {
  background-color: orange;
} */
.homepage-link-container {
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
}
.homepage-link-container:hover {
  transition: 0.1s ease-in-out;
}
.homepage-link-text {
  font-weight: 500;
}
@media (min-width: 901px) {
  .homepage-link-container {
    text-align: center;
    min-width: 250px;
    height: 13em;
    cursor: pointer;
  }
  .homepage-link-container:hover {
    background-color: var(--care-blue-faint);
  }
  .homepage-link-text {
    color: var(--care-blue-primary);
    white-space: nowrap;
    margin: 0 auto 1em auto;
    width: 100%;
  }
  #homepage-icon-links {
    padding-left: 16px;
    padding-right: 16px;
    height: 25em;
    justify-content: space-evenly;
  }
  .homepage-icon {
    width: 8em;
    height: 8em;
    filter: opacity(28%);
  }
}
@media (max-width: 900px) {
  .homepage-link-container {
    display: flex;
    align-items: center;
    border: 1px solid var(--care-blue-faint);
    background-color: var(--care-blue-primary);
    width: 70%;
    min-width: 300px;
  }
  .homepage-link-container:hover {
    background-color: var(--care-blue-hover);
  }

  .homepage-link-text {
    color: white;
  }

  #homepage-icon-links {
    width: 100vw;
    height: 20em;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2em 0;
    /* margin-bottom: 2em; */
  }

  .homepage-icon {
    display: none;
  }
}
</style>
