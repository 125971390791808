<template>
  <WelcomeBanner />
  <div class="back-white">
    <IconLinks />
    <HeadshotIntro />
    <CoreValues />
    <SchedulePrompt />
    <HomeTestimonials />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import WelcomeBanner from "./HomePageComponents/WelcomeBanner.vue";
import HeadshotIntro from "./HomePageComponents/HeadshotIntro.vue";
import IconLinks from "./HomePageComponents/IconLinks.vue";
import SchedulePrompt from "./HomePageComponents/SchedulePrompt.vue";
import HomeTestimonials from "./HomePageComponents/HomeTestimonials.vue";
import CoreValues from "./HomePageComponents/CoreValues.vue";
export default defineComponent({
  name: "HomePage",
  components: {
    HeadshotIntro,
    IconLinks,
    SchedulePrompt,
    WelcomeBanner,
    HomeTestimonials,
    CoreValues,
  },
});
</script>
<style>
@media (min-width: 901px) {
  .highlight-font-size {
    font-size: 2em;
  }
  .sub-highlight-font-size {
    font-size: 1.5em;
  }
}
@media (max-width: 900px) {
  .highlight-font-size {
    font-size: clamp(24px, 4vw, 28px);
  }
  .sub-highlight-font-size {
    font-size: clamp(18px, 2.5vw, 22px);
  }
}
</style>
