<template>
  <div id="slideshow-container" class="flex align-c">
    <TransitionGroup tag="ul" name="slide">
      <li
        v-for="(item, itemIndex) in reviews"
        :key="itemIndex"
        v-show="currentReview === itemIndex"
        class="review-slide"
      >
        <p>"{{ item.message }}"</p>
        <p>-&nbsp;{{ item.source }}</p>
      </li>
    </TransitionGroup>
  </div>
  <!-- <div><button @click="nextReview">Next</button></div> -->
</template>
<script lang="ts">
import { TransitionGroup, ref } from "vue";
import { Reviews } from "@/constants/reviews";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ReviewSlideShow",
  components: {
    TransitionGroup,
  },
  setup() {
    const currentReview = ref(0);
    const reviews = ref(Reviews);
    // const nextReview = () => {
    //   currentReview.value = (currentReview.value + 1) % reviews.value.length;
    // };
    return { reviews, currentReview };
  },
  mounted() {
    setInterval(() => {
      this.currentReview = (this.currentReview + 1) % this.reviews.length;
    }, 7000);
  },
});
</script>
<style scoped>
ul {
  list-style: none;
  padding: 1em;
}
#slideshow-container {
  position: relative;
  border: 2px solid white;
  border-radius: 4%;
  width: 22em;
  height: 200px;
  overflow: hidden;
}

@keyframes slide-in {
  0% {
    transform: translateX(-120%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(120%);
  }
}

.review-slide {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-120%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.slide-enter-active,
.slide-leave-active {
  animation: slide-in 1s forwards;
}

.slide-leave-active {
  animation: slide-out 1s forwards;
}
@media (max-width: 900px) {
  #slideshow-container {
    margin: 32px 0;
    max-width: 80%;
  }
}
</style>
