<template>
  <div id="services-main-container">
    <div id="services-header">
      <div id="services-header-overlay" />
      <div id="services-header-text" class="text-white">
        <div class="services-title">
          Professional Development <br />and Consultative<br />
          Services
        </div>
        <div class="services-header-blurb">
          CARE Behavioral Health provides a<br />wide-range of consultative and
          professional<br />development options to help your staff<br />gain the
          skills they need to do their best work.
        </div>
      </div>
    </div>
    <div id="services-main-blurb" class="text-blue">
      CARE Behavioral Health is a team of highly qualified personnel from a
      variety of disciplines that provide consultative services to school
      systems across the US. CARE BH's knowledge of public school systems
      coupled with in-depth knowledge and experience working with students and
      their families in the home and community-based settings allows them to
      focus on developing support systems for families and professionals who are
      striving to improve the overall quality of life for their children and
      students.
    </div>
    <div
      id="services-main-testimonials-container"
      class="flex j-evenly align-c mlr-auto text-blue"
    >
      <div id="testimonial-blurb">What our clients are saying...</div>
      <ReviewSlideShow />
    </div>
    <div id="service-selection-container" class="static-medium">
      <router-link
        to="/services/professional-development"
        class="services-selection-button button-link flex j-between align-c"
      >
        <div>Professional Development</div>
        <div>></div>
      </router-link>
      <router-link
        to="/services/consultations"
        class="services-selection-button button-link flex j-between align-c"
      >
        <div>Consultative Services</div>
        <div>></div>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ReviewSlideShow from "./ReviewSlideshow.vue";
export default defineComponent({
  name: "ServicesMain",
  components: {
    ReviewSlideShow,
  },
});
</script>
<style>
#services-header {
  background-image: url("/public/images/AdobeStock_199931368-scaled.jpeg");
  background-repeat: no-repeat;
  overflow: hidden;
}
#services-header-overlay {
  backdrop-filter: grayscale(100%);
  left: 0%;
}
.services-title {
  font-weight: 900;
}
.services-header-blurb {
  color: var(--care-yellow-primary);
}
#services-main-testimonials-container {
  background-color: var(--care-blue-faint);
}
#testimonial-blurb {
  text-wrap: wrap;
}
.services-selection-button {
  margin: 2em auto;
}
@media (min-width: 901px) {
  #services-header {
    /* background-image: url("/public/images/AdobeStock_199931368-scaled.jpeg"); */
    height: 40em;
    /* background-repeat: no-repeat; */
    background-size: 80em;
    background-position: right 0%;
    /* min-width: 1200px; */
    /* overflow: hidden; */
  }

  #services-header-overlay {
    background-image: linear-gradient(
      to right,
      var(--care-blue-primary) 35%,
      transparent
    );
    /* backdrop-filter: grayscale(100%); */
    height: 100%;
    width: 100%;
    top: -5%;
    /* left: 0%; */
    z-index: -2;
  }
  #services-header-text {
    position: relative;
    bottom: 70%;
    left: 5%;
  }
  .services-title {
    font-size: 4em;
    /* font-weight: 900; */
    filter: opacity(60%);
  }
  .services-header-blurb {
    font-weight: 100;
    font-size: 1.5em;
    /* color: var(--care-yellow-primary); */
  }
  #services-main-blurb {
    width: 38em;
    font-size: 1.25em;
    margin: 6em auto;
  }
  #services-main-testimonials-container {
    /* background-color: var(--care-blue-faint); */
    height: 20em;
    width: 100%;
  }
  #testimonial-blurb {
    font-size: xxx-large;
    /* text-wrap: wrap; */
    width: 40%;
  }

  #service-selection-container {
    margin: 4em;
  }

  .services-selection-button {
    width: 60%;
    min-width: 400px;
    /* margin: 2em auto; */
  }
}
@media (max-width: 900px) {
  #services-header {
    /* background-image: url("/public/images/AdobeStock_199931368-scaled.jpeg");
    background-repeat: no-repeat;
    overflow: hidden; */
    height: 20em;
    background-size: cover;
    background-position: right 10%;
  }

  #services-header-overlay {
    position: absolute;
    background-color: var(--care-blue-primary);
    filter: opacity(90%);
    /* backdrop-filter: grayscale(100%); */
    height: 20em;
    width: 100%;
    /* left: 0%; */
    z-index: 0;
  }
  #services-header-text {
    margin-left: 16px;
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .services-title {
    z-index: 3;
    font-size: clamp(32px, 5vw, 6vw);
    /* font-weight: 900; */
  }
  .services-header-blurb {
    z-index: 3;
    font-weight: 500;
    /* color: var(--care-yellow-primary); */
    /* background-color: var(--care-blue-hover); */
    padding: 8px;
  }
  #services-main-blurb {
    width: 90%;
    max-width: 600px;
    margin: 4em auto;
  }
  #services-main-testimonials-container {
    display: flex;
    flex-wrap: wrap;
    /* background-color: var(--care-blue-faint); */
    height: fit-content;
    width: auto;
  }
  #testimonial-blurb {
    margin-top: 32px;
    font-size: xx-large;
    /* text-wrap: wrap; */
    text-align: center;
    width: 100%;
    max-width: 90%;
  }

  #service-selection-container {
    margin: 32px;
  }

  .services-selection-button {
    width: 70%;
    /* margin: 2em auto; */
  }
}
</style>
