import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66388c98"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "slideshow-container",
  class: "flex align-c"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      tag: "ul",
      name: "slide"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviews, (item, itemIndex) => {
          return _withDirectives((_openBlock(), _createElementBlock("li", {
            key: itemIndex,
            class: "review-slide"
          }, [
            _createElementVNode("p", null, "\"" + _toDisplayString(item.message) + "\"", 1),
            _createElementVNode("p", null, "- " + _toDisplayString(item.source), 1)
          ])), [
            [_vShow, _ctx.currentReview === itemIndex]
          ])
        }), 128))
      ]),
      _: 1
    })
  ]))
}