<template>
  <footer id="footer-container" class="back-white flex j-evenly align-c">
    <div id="footer-links" class="footer-item flex j-between">
      <router-link to="/about" class="text-link-blue">About</router-link>
      <router-link to="/services" class="text-link-blue">Services</router-link>
      <router-link to="/resources" class="text-link-blue"
        >Resources</router-link
      >
      <router-link to="/contact" class="text-link-blue">Contact</router-link>
    </div>
    <div class="footer-item flex align-c">
      <img class="footer-icon" src="/icons/CARE envelope.svg" alt="envelope" />
      <a class="t-no-decoration" href="mailto:info@CareBehavioralHealth.com"
        >info@CareBehavioralHealth.com</a
      >
    </div>
    <div class="footer-item flex align-c">
      <img class="footer-icon" src="/icons/phone-1.svg" alt="phone" />
      <a class="t-no-decoration" href="tel:+17025619192">(702) 561-9192</a>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterVue",
});
</script>
<style>
#footer-container {
  margin-top: auto;
  width: auto;
  /* height: 6em; */
  flex-wrap: wrap;
  padding: 1em;
}

#footer-links {
  width: 18em;
  /* margin-bottom: 1em;
  padding: 1em; */
}
.footer-icon {
  height: 3em;
  width: 3em;
  margin-right: 1em;
}
.footer-item {
  margin-bottom: 1em;
  padding: 0.5em;
}
@media (max-width: 900px) {
  #footer-container {
    background-color: var(--care-blue-faint);
  }
}
</style>
