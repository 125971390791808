<template>
  <div id="paragraph-content" class="w-70 mlr-auto text-blue">
    {{ paragraphText }}
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ParagraphContent",
  props: {
    paragraphText: {
      type: String,
      required: true,
    },
  },
});
</script>
<style scoped>
#paragraph-content {
  margin: 1em 0;
  width: 800px;
}
@media (max-width: 900px) {
  #paragraph-content {
    max-width: 90%;
    font-size: 14px;
    margin-bottom: 2em;
  }
}
</style>
