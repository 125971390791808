<template>
  <div id="about-header-container" class="flex j-center back-blue text-white">
    <div id="image-first-paragraph" class="flex align-c mlr-auto">
      <div id="care-history-image" class="about-image" />
      <div>
        <div class="static-large">Our History</div>
        <p class="care-history-text-flex">
          CARE was founded in 2002 by Dr. Schaefer, BCBA-D, LBA in Nevada to
          ensure public charter schools were following state and federal
          guidelines while meeting the individualized needs of students with
          disabilities participating in a wide range of program models. The CARE
          team expanded to include a group of professionals from a variety of
          disciplines who provided consultative services to school systems
          around the USA. CARE has successfully implemented multi-year
          initiatives with districts and state agencies. Dr. Schaefer also
          served as a consultant on a federal grant designed to provide
          professional development and coaching to school personnel working with
          students with Autism and related disorders.
        </p>
        <p class="care-history-text-flex">
          Our highly sought after consultative services are uniquely tailored to
          meet the needs of the specific organization seeking services. From
          formalized assessment training, staff development on evidenced-based
          practices and implementation of principles of applied behavior
          analysis (ABA), to student- specific services and parent training,
          CARE BH is highly capable of facilitating improvements across a wide
          variety of areas in any school setting.
        </p>
      </div>
    </div>
    <div class="flex column align-c section-max-w mlr-auto">
      <p class="about-text-block">
        In 2003, CARE began providing in-home and community-based ABA services
        to individuals with ASD and their families throughout North America.
        These services were in high demand but sparsely available, leading
        numerous parents and professionals to continually request CARE to
        provide ABA services to more locations. In 2009, CARE opened ABA
        services in San Antonio, TX serving military families and their
        dependents with ASD. CARE continued to expand services in new states and
        locations until CARE was sold in February 2022.
      </p>
      <p class="about-text-block">
        CARE Behavioral Health (CARE BH) was formed in 2020 with the goal of
        continuing to provide premier school consultative services to districts
        across the USA. CARE BH provides staff development training, conducts
        program evaluations and works directly with school systems to provide
        ongoing coaching, technical assistance and support. CARE BH promotes the
        use of evidenced-based practices to meet the individualized needs of
        students with and without disabilities and works with districts to
        effectively implement inclusive practices to ensure positive student
        outcomes.
      </p>
    </div>
  </div>
  <div id="about-the-founder" class="text-blue">
    <div id="image-first-paragraph" class="flex align-c mlr-auto">
      <div id="headshot" class="about-image" />
      <div>
        <div class="static-large">Janet Schaefer, BCBA-D, LBA</div>
        <div class="static-medium">
          CEO and Founder of CARE Behavioral Health
        </div>
        <p class="care-history-text-flex-bio">
          Dr. Schaefer founded CARE in 2002 in Nevada and has been a Board
          Certified Behavior Analyst- Doctoral (BCBA-D) level since 2003. She
          has supervised numerous professionals who have fulfilled the
          requirements of the BACB and have become BCBAs. Dr. Schaefer received
          a doctoral degree in special education from the University of Nevada
          at Las Vegas (UNLV) in 1999; a master's degree in special education
          from California State University at Dominguez Hills (CSUDH) in 1991
          and finished the administrative program at CSUDH in1993. She completed
          coursework in Applied Behavior Analysis (ABA) at the University of
          North Texas in 2001 and at the State University of Rutgers in New
          Jersey in 1999 and participated in a summer internship at the Douglass
          Developmental Disabilities Center. She also holds two undergraduate
          degrees from the University of Saskatchewan in Canada including a
          Bachelor of Arts degree in sociology and a Bachelor of Education
          degree.
        </p>
      </div>
    </div>
    <div class="flex column align-c section-max-w mlr-auto">
      <p class="about-text-block">
        Dr. Schaefer has worked as a preschool teacher as well as a special
        education teacher on several elementary campuses and in an inclusive
        preschool program at UNLV. She was also a teacher trainer at one of four
        model demonstration sites in Nevada designed to utilize principles of
        ABA with young children from 1999-2001. Dr. Schaefer has also worked in
        other district level positions including Preschool- , Federal Programs-,
        Special Education- Coordinator, Autism Program Specialist, and as an
        Assistant Principal.
      </p>
      <p class="about-text-block">
        Dr. Schaefer has worked with school districts to develop transition
        planning for secondary students with disabilities with the goal of
        improving their overall quality of life and level of independence. She
        has provided training to groups on various models of person-centered
        planning such as the McGill Action Planning System (MAPS) and the
        Planning Alternatives Tomorrow with Hope (PATH). CARE has successfully
        implemented multi-year initiatives with districts and state agencies.
        Dr. Schaefer has worked as a consultant on a multi-year federal grant
        designed to provide professional development in Autism. She has also
        written and worked on several grants and projects designed to support
        innovative services for students with ASD. Dr. Schaefer and her team of
        highly qualified professionals continue to provide consultative services
        to districts and state agencies throughout the US.
      </p>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "AboutUs",
});
</script>
<style scoped>
#image-first-paragraph {
  max-width: 90vw;
}
#about-header-container {
  flex-wrap: wrap;
  padding-bottom: 3em;
}
#care-history-image {
  background-image: url("/public/images/ABA-website-1.png");
  max-width: 90vw;
}
.about-image {
  background-repeat: no-repeat;
}
.care-history-text-flex {
  max-width: 90vw;
}
.care-history-text-flex-bio {
  max-width: 90vw;
}
#about-the-founder {
  margin-top: 3em;
}

#headshot {
  background-image: url("/public/images/jan_bio_old.jpeg");
}
@media screen and (min-width: 901px) {
  #image-first-paragraph {
    justify-content: space-between;
    width: 960px;
  }
  .about-section {
    margin-bottom: 1em;
  }
  #care-history-image {
    background-position: left 60% top 50%;
    background-size: 800px;
    height: 600px;
    width: 400px;
  }
  #headshot {
    background-position: right;
    background-size: 280px;
    height: 445px;
    width: 40%;
    min-width: 300px;
  }
  .about-image {
    margin-right: 1em;
  }
  .about-text-block {
    max-width: 90vw;
  }
  .care-history-text-flex {
    width: 480px;
    margin-bottom: 0;
  }
  .care-history-text-flex-bio {
    width: 600px;
    max-width: 52vw;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 900px) {
  .about-text-block {
    margin: 0 1em 2em 1em;
    font-size: 14px;
    max-width: 90vw;
  }
  #image-first-paragraph {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  #care-history-image {
    background-position: 60% 20%;
    background-size: 600px;
    margin: 1em 0;
    height: 400px;
    width: 600px;
  }
  #headshot {
    background-position: center;
    background-size: 280px;
    height: 500px;
    width: 300px;
  }
  .care-history-text-flex {
    margin: 2em 0;
    font-size: 14px;
    max-width: 90vw;
  }
  .care-history-text-flex-bio {
    margin: 2em 0;
    font-size: 14px;
    max-width: 90vw;
  }
}
</style>
