import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6737a13f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "alert-modal",
  class: "back-blue text-yellow"
}
const _hoisted_2 = { class: "static-large mlr-auto" }
const _hoisted_3 = { class: "alert-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.successOrFailure ? "Message Sent!" : "Error..."), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message), 1),
        (_ctx.isOpen && !_ctx.successOrFailure)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeAlertModal && _ctx.closeAlertModal(...args))),
              class: "email-button static-medium mlr-auto"
            }, " ok "))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}