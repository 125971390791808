<template>
  <div id="bullet-content" class="text-blue mlr-auto">
    <div v-if="bulletData.header" class="static-medium mb-1">
      {{ bulletData.header }}:
    </div>
    <ol v-if="bulletData.numbered">
      <li :key="itemIndex" v-for="(item, itemIndex) in bulletData.list">
        {{ item }}
      </li>
    </ol>
    <ul v-else>
      <li :key="itemIndex" v-for="(item, itemIndex) in bulletData.list">
        {{ item }}
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import type { BulletList } from "@/constants/services";
import { defineComponent } from "vue";
import type { PropType } from "vue";
export default defineComponent({
  name: "BulletContent",
  props: {
    bulletData: {
      type: Object as PropType<BulletList>,
      required: true,
    },
  },
});
</script>
<style scoped>
#bullet-content {
  margin: 1em 0 2em 0;
  width: 800px;
}
li {
  margin-right: 1em;
  /* margin-bottom: 0.5em; */
  line-height: 1.5em;
}

ol,
ul {
  margin: 0;
}
@media screen and (max-width: 900px) {
  #bullet-content {
    max-width: 90vw;
  }
  li {
    font-size: clamp(14px, 2vw, 16px);
  }
}
</style>
