<template>
  <div id="paragraph-content" class="w-70 mlr-auto">{{ noteText }}</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NoteContent",
  props: {
    noteText: {
      type: String,
      required: true,
    },
  },
});
</script>
<style scoped></style>
