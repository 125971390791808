export const Resources = {
  parentResources: [
    {
      title: "Top Ten Autism Websites Recommended by Parents",
      source: "Eden2.org",
      url: "https://eden2.org/top-10-autism-websites-recommended-by-parents/",
    },
    {
      title: "Self Screening Questionnaire “M-CHAT”",
      source: "In Home Autism Self Assessment Test",
      url: "https://www.autismspeaks.org/screen-your-child",
    },
  ],
  caregiverResources: [
    {
      title: "Texas Autism Resources",
      source: "TSLAT",
      url: "https://www.txautism.net/",
    },
    {
      title: "Autism Speaks Resources",
      source: "Autism Speaks",
      url: "https://www.autismspeaks.org",
    },
    {
      title: "Autism Data Statistics",
      source: "Centers for Disease Control and Prevention",
      url: "https://www.cdc.gov/ncbddd/autism/data.html",
    },
    {
      title: "Learn the Signs",
      source: "Centers for Disease Control and Prevention",
      url: "http://www.cdc.gov/ncbddd/autism/facts.html",
    },
    {
      title:
        "27 Evidence-Based Practices Identified by the National Professional Development Center on ASD",
      source: "Autism Focused Intervention Resources Modules (AFIRM)",
      url: "https://afirm.fpg.unc.edu/afirm-modules",
    },
    {
      title:
        "Information and Professional Development for anyone who supports, instructs, works with, or lives with someone with autism",
      source: "Autism Internet Modules",
      url: "https://autisminternetmodules.org/",
    },
    {
      title:
        "Evidence-Based Practice and Autism in the Schools, 2nd Edition (now free of charge)",
      source: "National Autism Center",
      url: "https://nationalautismcenter.org/national-standards/",
    },
    {
      title: "Educating Children with Autism (2001) - free download",
      source: "National Academies Press",
      url: "https://nap.nationalacademies.org/catalog/10017/educating-children-with-autism",
    },
    {
      title: "National Institutes of Health",
      source: "",
      url: "https://www.health.nih.gov/topic/Autism", //TODO: this does not seem to link where jan intends
    },
    {
      title: "National Institute of Mental Health",
      source: "",
      url: "https://www.nimh.nih.gov/",
    },
    {
      title: "Behavior Analyst Certification Board (BACB)",
      source: "",
      url: "https://www.bacb.com",
    },
    {
      title: "Autism Focused Intervention Resources and Modules",
      source: "AFIRM",
      url: "https://afirm.fpg.unc.edu/afirm-modules",
    },
  ],
};
