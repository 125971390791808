export interface CAREReview {
  message: string;
  source: string;
}

export const Reviews: CAREReview[] = [
  {
    message:
      "Thank you so much for the information on ADOS-2. It is exactly what I needed. Your help is greatly appreciated.",
    source: "School Psychologist - NV",
  },
  {
    message:
      "I wanted to write and let you know what a wonderful presenter you are.",
    source: "SLP-CCC, Austin, TX",
  },
  {
    message:
      "It was great to get to visit you yesterday! Thank you for all of your help! We appreciate you!",
    source: "Classroom Teacher, New Mexico",
  },
  {
    message:
      "Your knowledge and expertise through that training was second to none.",
    source: "LSSP, Katy ISD",
  },
  {
    message:
      "Your training for paraprofessionals was great. This is a training our entire district needs",
    source: "Middle School Principal, New Mexico",
  },
  {
    message:
      "I liked how we learned something new everyday of the training and how it all fits together",
    source: "Hands-on Training: Principles of ABA - Teacher, New Mexico",
  },
  {
    message:
      "Loved the introduction to Picture Exchange Communication System (PECS) and seeing how it can be implemented with a student",
    source: "Hands-on Training Participant - Teacher, New Mexico",
  },
  {
    message:
      "I wanted to tell you are a great presenter with a wealth of information. I definitely want to attend more sessions that you offer.",
    source:
      "Evaluating Girls Suspected of Autism - School Psychology Intern, Texas",
  },
];
