<template>
  <div id="navigation-container" class="flex j-between">
    <router-link @click="closeOnNavigate" to="/" class="h-inherit">
      <img class="h-inherit" src="/images/CARE-4square-logo.jpeg" alt="CARE" />
    </router-link>
    <div id="links-search-container" v-if="showMenu" class="flex align-c">
      <div id="nav-links" class="flex">
        <router-link
          @click="closeOnNavigate"
          class="text-link-blue nav-link static-medium"
          to="/about"
        >
          About
          <span class="underline"></span>
        </router-link>
        <router-link
          @click="closeOnNavigate"
          class="text-link-blue nav-link static-medium"
          to="/services"
        >
          Services
          <span class="underline"></span>
        </router-link>
        <router-link
          @click="closeOnNavigate"
          class="text-link-blue nav-link static-medium"
          to="/resources"
        >
          Resources
          <span class="underline"></span>
        </router-link>
        <router-link
          @click="closeOnNavigate"
          class="text-link-blue nav-link static-medium"
          to="/contact"
        >
          Contact
          <span class="underline"></span>
        </router-link>
      </div>
      <NavSearch />
    </div>
    <div class="mobileMenuHamburgerContainer">
      <input
        type="checkbox"
        id="nav-toggle"
        class="active"
        @click="toggleNav"
      />
      <label htmlFor="nav-toggle" class="hamburgerMenu">
        <span></span>
      </label>
    </div>
    <!-- <div style="position: absolute; left: 50%">
      {{ showMenu }}
    </div> -->
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import NavSearch from "./NavSearch.vue";
export default defineComponent({
  name: "NavBar",
  components: {
    NavSearch,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    showMenu: function () {
      if (this.showMenu && window.innerWidth <= 900) {
        document.body.style.overflowY = "hidden";
        window.scrollTo(0, 0);
        return;
      } else {
        document.body.style.overflowY = "unset";
        return;
      }
    },
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 900) {
        const menuCheckbox = document.getElementById(
          "nav-toggle"
        ) as HTMLInputElement;
        if (menuCheckbox) menuCheckbox.checked = false;
        this.showMenu = false;
        return false;
      } else {
        document.body.style.overflowY = "unset";
        this.showMenu = true;
        return true;
      }
    },
    toggleNav() {
      this.showMenu = !this.showMenu;
    },
    handlePrevent(e: Event) {
      e.preventDefault();
    },
    //TODO: if issues, this was added to handle closing nav on navigate
    closeOnNavigate() {
      if (this.showMenu && window.innerWidth <= 900) this.showMenu = false;
      const menuCheckbox = document.getElementById(
        "nav-toggle"
      ) as HTMLInputElement;
      if (menuCheckbox) menuCheckbox.checked = false;
    },
  },
});
</script>
<style scoped>
#navigation-container {
  /* position: fixed; */
  /* top: 0%;
  width: 100%;
  */
  height: 96px;
  background-color: white;
  padding: 0 0.25em;
  z-index: 10;
}

@media (min-width: 901px) {
  #navigation-container {
    position: fixed;
    top: 0%;
    width: 100%;
    height: 96px;
  }
  #nav-links {
    justify-content: space-between;
    width: 400px;
  }
  .mobileMenuHamburgerContainer {
    display: none;
  }
  .nav-link {
    position: relative;
  }
  .underline {
    position: absolute;
    background-position: center;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1.5px;
    background-color: var(--care-blue-hover);
    transition: width 0.2s;
  }

  .nav-link:hover .underline {
    width: 100%;
  }
}
/* tablet and mobile */
@media only screen and (max-width: 900px) {
  #nav-links {
    position: fixed;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    top: 0%;
    left: 0%;
    width: 100vw;
    z-index: 11;
    background-color: white;
  }
  .mobileMenuHamburgerContainer {
    z-index: 11;
    display: flex;
    align-items: center;
  }
  .hamburgerMenu {
    display: inline-block;
    cursor: pointer;
    height: 3em;
    background-color: transparent;
    width: 2em;
    height: 2em;
    margin: 1em;
    /* outline: 1px solid orange; */
    z-index: 20;
  }
  .hamburgerMenu span,
  .hamburgerMenu:before,
  .hamburgerMenu:after {
    content: "";
    width: 2em;
    border-bottom: 3px solid var(--care-blue-primary);
    position: absolute;
    top: 47px;
    right: 20px;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 2px;
  }

  .hamburgerMenu:before {
    transform: translateY(-10px);
  }

  .hamburgerMenu:after {
    transform: translateY(10px);
  }

  .active:checked + .hamburgerMenu span {
    transform: scaleX(0);
  }

  .active:checked + .hamburgerMenu:before {
    transform: rotate(45deg);
  }

  .active:checked + .hamburgerMenu:after {
    transform: rotate(-45deg);
  }

  .active {
    display: none;
  }
}
/* tablet only */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  #nav-links {
    height: 50vh;
    padding: 2em 0;
  }
}
/* mobile only */
@media only screen and (max-width: 600px) {
  #nav-links {
    height: 100vh;
  }
}
</style>
