import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "service-detail-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StandardPageHeader = _resolveComponent("StandardPageHeader")!
  const _component_DynamicContent = _resolveComponent("DynamicContent")!
  const _component_ParticipantsLength = _resolveComponent("ParticipantsLength")!
  const _component_ContactUs = _resolveComponent("ContactUs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_StandardPageHeader, {
      icon: '/icons/engine-gears-icon.svg',
      title: _ctx.service.title
    }, null, 8, ["title"]),
    (_ctx.service)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_DynamicContent, { service: _ctx.service }, null, 8, ["service"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ParticipantsLength, { "service-item": _ctx.service }, null, 8, ["service-item"]),
    _createVNode(_component_ContactUs)
  ], 64))
}