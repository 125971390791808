<template>
  <div id="introduction-info" class="flex w-100">
    <div class="headshot-container h-inherit flex">
      <img
        id="headshot"
        src="/images/jan_bio_old.jpeg"
        alt="Jan Schaeffer picture"
      />
    </div>
    <div class="intro-info-text flex column">
      <div class="light-weight static-large">
        Janet Schaefer, BCBA-D, LBA
        <div class="position-title-text mid-weight">CEO and Founder</div>
      </div>
      <router-link
        to="/about"
        class="about-intro-link text-link-basic midsize-text"
      >
        Learn more about who we
        <span class="no-wrap-text">are &nbsp;></span>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "HeadshotIntro",
});
</script>
<style scoped>
@media (min-width: 651px) {
  #headshot {
    margin-left: auto;
    height: 24em;
  }
  .headshot-container {
    background-color: var(--care-blue-faint);
    height: 26em;
    width: 40%;
    min-width: 300px;
    padding: 2em 0;
  }

  .intro-info-text {
    background-color: var(--care-blue-primary);
    color: white;
    padding-left: 1em;
    justify-content: space-evenly;
    width: 60%;
  }
  .about-intro-link {
    color: white;
  }
}

@media (max-width: 650px) {
  #introduction-info {
    flex-direction: column;
    /* background-color: var(--care-blue-faint); */
    color: var(--care-blue-primary);
    margin-bottom: 2em;
  }
  #headshot {
    margin: 2em auto 1em auto;
    height: 30em;
    outline: 3px solid var(--care-blue-faint);
  }
  .position-title-text {
    color: var(--care-blue-primary);
  }

  .intro-info-text {
    height: 10em;
    /* padding-left: 1em; */
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
  }
  .about-intro-link {
    width: fit-content;
    color: var(--care-blue-primary);
  }
}
</style>
