<template>
  <StandardPageHeader
    :icon="'/icons/engine-gears-icon.svg'"
    :title="service.title"
  />
  <div id="service-detail-container" v-if="service">
    <DynamicContent :service="service" />
  </div>
  <ParticipantsLength :service-item="service" />
  <ContactUs />
</template>
<script lang="ts">
import type { PropType } from "vue";
import type { CAREService } from "@/constants/services";
import { ref, watchEffect, defineComponent } from "vue";
import { useRoute } from "vue-router";
import DynamicContent from "./ContentComponents/DynamicContent.vue";
import StandardPageHeader from "./StandardPageHeader.vue";
import ContactUs from "./ContentComponents/ContactUs.vue";
import ParticipantsLength from "./ContentComponents/ParticipantsLength.vue";
export default defineComponent({
  components: {
    DynamicContent,
    StandardPageHeader,
    ContactUs,
    ParticipantsLength,
  },
  props: {
    allServices: {
      type: Array as PropType<CAREService[]>,
      required: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const service = ref();

    watchEffect(() => {
      const foundService = props.allServices?.find((service: CAREService) => {
        return service.route === route.params.route;
      });
      service.value = foundService;
    });
    // const isLastParticipant = (index: number) =>
    //   index === service.value.participants.list.length - 1;
    return { service };
  },
});
</script>
<style>
#service-detail-header {
  padding: 2em;
}
#service-detail-header-title {
  font-size: 3em;
  font-weight: 800;
}

.gear-icon {
  filter: opacity(50%) invert();
  height: 8em;
  margin: 0 3em;
}
@media (min-width: 901px) {
  #service-detail-container {
    margin: 4em 0 4em 0;
  }
}
</style>
