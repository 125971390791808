<template>
  <div id="bullet-content" class="text-blue mlr-auto">
    <div v-if="bulletData.header" class="static-medium mb-1">
      {{ bulletData.header }}:
    </div>
    <div
      class="compound-bullet-item"
      :key="itemIndex"
      v-for="(item, itemIndex) in bulletData.list"
    >
      <div class="compound-bullet-header">{{ item.header }}</div>
      <ul>
        <li :key="bulletIndex" v-for="(bullet, bulletIndex) in item.list">
          {{ bullet }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import type { CompoundBulletList } from "@/constants/services";
import { defineComponent } from "vue";
import type { PropType } from "vue";
export default defineComponent({
  name: "CompoundBulletContent",
  props: {
    bulletData: {
      type: Object as PropType<CompoundBulletList>,
      required: true,
    },
  },
});
</script>
<style scoped>
#bullet-content {
  margin: 1em 0 2em 0;
  width: 800px;
}
li {
  margin-right: 1em;
  /* margin-bottom: 0.5em; */
  line-height: 1.5em;
}
.compound-bullet-item {
  margin-bottom: 1em;
}
.compound-bullet-header {
  font-weight: 600;
}

ol,
ul {
  margin: 0;
}
@media screen and (max-width: 900px) {
  #bullet-content {
    max-width: 90vw;
  }
  li {
    font-size: clamp(14px, 2vw, 16px);
  }
}
</style>
