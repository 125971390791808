import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45cc6489"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "searchContainer",
  id: "search-container"
}
const _hoisted_2 = {
  key: 0,
  id: "search-prompt-results",
  class: "flex column back-blue text-white"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: "search-input",
      placeholder: "search our services",
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
    }, null, 512), [
      [_vModelText, _ctx.searchValue]
    ]),
    (_ctx.showSearch && _ctx.searchPromptResults.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchPromptResults, (service, serviceIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "search-result-item",
              key: serviceIndex,
              onClick: ($event: any) => (_ctx.navigateToService(service.category, service.route))
            }, _toDisplayString(service.title), 9, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}