import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c83c14a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "standard-header",
  class: "back-blue flex align-c"
}
const _hoisted_2 = {
  id: "header-info",
  class: "flex align-c"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  id: "header-title",
  class: "text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        id: "header-icon",
        src: _ctx.icon,
        alt: "dev icon"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ])
  ]))
}