import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25efc269"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "content-header",
  class: "back-blue text-white mlr-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.headerText), 1))
}