<template>
  <div
    id="length-participant-container"
    class="section-max-w mlr-auto flex j-between"
    v-if="serviceItem && serviceItem.length && serviceItem.participants"
  >
    <div class="logistics-info-container flex align-c">
      <img
        class="logistics-icon"
        src="/icons/clock-line-icon.svg"
        alt="engine gears"
      />
      <div class="logistics-info flex align-s">
        <div class="static-large text-white">Length of Session:</div>
        <div class="static-medium text-blue">{{ serviceItem.length }}</div>
      </div>
    </div>
    <div
      id="participant-info-container"
      class="logistics-info-container flex align-c"
    >
      <img
        class="logistics-icon"
        src="/icons/meeting-icon.svg"
        alt="engine gears"
      />
      <div class="flex column align-s">
        <div class="static-large text-white">Participants:</div>
        <div>
          <div
            class="participant-item text-blue"
            :key="pIndex"
            v-for="(p, pIndex) in serviceItem.participants.list"
          >
            {{ p
            }}<span v-if="pIndex !== serviceItem.participants.list.length - 1"
              >,&nbsp;</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { CAREService } from "@/constants/services";
export default defineComponent({
  name: "ParticipantsLength",
  props: {
    serviceItem: {
      type: Object as PropType<CAREService>,
      required: true,
    },
  },
});
</script>
<style scoped>
#length-participant-container {
  background-color: var(--care-blue-faint);
  padding: 1em;
}
.logistics-info-container {
  width: 50%;
  padding: 1em;
}
.participant-item {
  width: fit-content;
  float: left;
}
.logistics-icon {
  filter: opacity(50%) invert();
}
@media screen and (min-width: 901px) {
  .logistics-icon {
    height: 5em;
    width: 5em;
    margin-right: 2em;
  }
  .logistics-info {
    flex-direction: column;
  }
}
@media (max-width: 900px) {
  #length-participant-container {
    flex-direction: column;
    align-items: center;
  }
  #participant-info-container {
    width: 90%;
    max-width: 60vw;
  }
  .logistics-info-container {
    flex-direction: column;
  }
  .logistics-icon {
    height: 7em;
    width: 7em;
    margin-bottom: 2em;
  }
  .logistics-info {
    width: 300px;
    max-width: 60vw;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>
