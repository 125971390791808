<template>
  <div id="contact-container">
    <div id="contact-header" class="flex align-c text-blue">
      <div id="contact-header-title">Contact Us</div>
    </div>
    <div id="contact-form-container" class="back-blue text-white">
      <EmailForm />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import EmailForm from "./EmailForm.vue";
export default defineComponent({
  name: "ContactVue",
  components: {
    EmailForm,
  },
});
</script>
<style scoped>
#contact-header {
  height: 96px;
}
#contact-header-title {
  font-size: 3em;
  font-weight: 800;
  margin-left: 14vw;
}
#contact-form-container {
  padding: 2em 0;
}
</style>
