export function titleize(string: string): string {
  return string
    .replace(/[0-9]{2,}/g, (match: string) => ` ${match} `) // Insert spaces around numbers
    .replace(/[^A-Z0-9][A-Z]/g, (match: string) => `${match[0]} ${match[1]}`) // Insert space between uppercase letters
    .replace(
      /[A-Z][A-Z][^A-Z0-9]/g,
      (match: string) => `${match[0]} ${match[1]}${match[2]}`
    ) // Insert space between double uppercase letters
    .replace(/[ ]{2,}/g, () => " ") // Remove extra spaces
    .replace(/\s./g, (match) => match.toUpperCase()) // Capitalize words starting with a space
    .replace(/^./, (match) => match.toUpperCase()) // Capitalize the first character
    .trim(); // Trim leading and trailing spaces
}
