<template>
  <h2 id="content-header" class="back-blue text-white mlr-auto">
    {{ headerText }}
  </h2>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "HeaderContent",
  props: {
    headerText: {
      type: String,
      required: true,
    },
  },
});
</script>
<style scoped>
#content-header {
  padding: 0.5em;
  width: 80%;
}
@media screen and (max-width: 900px) {
  #content-header {
    font-size: clamp(20px, 2.75vw, 4vw);
  }
}
</style>
