<template>
  <ResultModal
    :is-open="openResultModal"
    :successOrFailure="emailSuccess"
    :message="resultMessage"
    @close-modal="resetForm"
  />
  <form
    id="contact-form"
    class="flex column align-c mlr-auto"
    v-on:submit="sendEmail($event)"
  >
    <label class="form-input" for="name"
      >Name:
      <span class="warning-note" v-if="warnings.fromName"
        >Name required</span
      ></label
    >
    <input
      type="text"
      id="name"
      class="form-input form-element"
      name="from_name"
      placeholder="Enter your name"
    />
    <label class="form-input" for="email"
      >Email:
      <span class="warning-note" v-if="warnings.emailPhone"
        >Need email or phone number</span
      >
    </label>
    <input
      type="text"
      id="email"
      class="form-input form-element"
      name="from_email"
      placeholder="Enter your email"
    />
    <label class="form-input" for="phone number"
      >Phone:
      <span class="warning-note" v-if="warnings.emailPhone"
        >Need email or phone number</span
      ></label
    >
    <input
      type="text"
      id="phoneNumber"
      class="form-input form-element"
      name="from_phoneNumber"
      placeholder="Enter your phone number"
    />
    <label class="form-input" for="position">Position:</label>
    <input
      type="text"
      id="position"
      class="form-input form-element"
      name="from_position"
      placeholder="Enter your position"
    />
    <label class="form-input" for="organization">Organization:</label>
    <input
      type="text"
      id="organization"
      class="form-input form-element"
      name="from_organization"
      placeholder="Enter your organization"
    />
    <label class="form-input" for="typeOfService">Type of Service:</label>
    <select
      id="service-type-dropdown"
      class="form-element form-input"
      name="from_service"
    >
      <option value="undefined" hidden>select from options</option>
      <option>Training For Evaluation Personnel</option>
      <option>Consultation for school district</option>
      <option>Other Consultations</option>
      <option>Not listed</option>
    </select>
    <label class="form-input" for="message">Message</label>
    <textarea id="contact-message-textarea" class="form-input" name="message" />
    <button
      id="email-form-submit-btn"
      class="email-button static-medium m-1"
      type="submit"
    >
      Send
    </button>
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import emailjs from "@emailjs/browser";
import type { EmailJSResponseStatus } from "@emailjs/browser";
import ResultModal from "@/utils/ResultModal.vue";
export default defineComponent({
  name: "EmailForm",
  components: {
    ResultModal,
  },
  data() {
    return {
      emailService: emailjs,
      warnings: {
        fromName: false,
        emailPhone: false,
      } as { [key: string]: boolean },
      env: process.env,
      emailSuccess: false,
      openResultModal: false,
      resultMessage: "" as
        | ""
        | "We will get back to you as soon as we can!"
        | "Sorry for the issue. Please contact us directly at info@carebehavioralhealth.com",
    };
  },
  methods: {
    setWarning(neededInput: string) {
      this.warnings[neededInput] = true;
    },
    resetWarnings() {
      this.warnings = {
        fromName: false,
        emailPhone: false,
      };
    },
    sendEmail(event: Event) {
      event.preventDefault();
      const target = event.target as HTMLFormElement;
      const formData = new FormData(target);
      let params: Record<string, unknown> = {};
      this.resetWarnings();
      const unfilledRequirements = [];
      for (let pair of formData.entries()) {
        if (!pair[1]) {
          unfilledRequirements.push(pair[0]);
        }
        params[pair[0]] = pair[1];
      }
      if (unfilledRequirements.includes("from_name")) {
        this.setWarning("fromName");
      }
      if (
        unfilledRequirements.includes("from_phoneNumber") &&
        unfilledRequirements.includes("from_email")
      ) {
        this.setWarning("emailPhone");
      }
      if (this.warnings.fromName || this.warnings.emailPhone) {
        return;
      } else {
        this.emailService
          .send(
            this.env.VUE_APP_EMAIL_SERVICE_KEY,
            this.env.VUE_APP_EMAIL_TEMPLATE_KEY,
            params,
            this.env.VUE_APP_EMAIL_PUBLIC_KEY
          )
          .then(
            (result: EmailJSResponseStatus) => {
              this.openResultModal = true;
              this.emailSuccess = true;
              this.resultMessage = "We will get back to you as soon as we can!";
              target.reset();
              return result;
            },
            (error: Error) => {
              console.log("FAILED...", error);
              this.resultMessage =
                "Sorry for the issue. Please contact us directly at info@carebehavioralhealth.com";
              this.openResultModal = true;
            }
          );
      }
    },
    resetForm() {
      this.emailSuccess = false;
      this.openResultModal = false;
    },
  },
});
</script>
<style scoped>
#service-type-dropdown {
  font-size: 0.75em;
  appearance: menulist-button;
  -webkit-appearance: menulist-button;
}
.form-element {
  height: 2em;
  border-radius: 4px;
  border: none;
}
/* .form-input {
  width: 400px;
} */
#contact-message-textarea {
  border-radius: 4px;
  border: none;
  resize: none;
  min-height: fit-content;
  height: 200px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.email-button {
  color: white;
  background-color: var(--care-blue-primary);
  padding: 0.5em;
  width: 100px;
  cursor: pointer;
  transition: 0.05s;
  border: 1px solid white;
  border-radius: 8px;
}

.email-button:hover {
  background-color: var(--care-blue-hover);
  transition: 0.05s;
}

.warning-note {
  color: red;
}
@media screen and (min-width: 501px) {
  .form-input {
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .form-input {
    width: 300px;
  }
}
</style>
