import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "back-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WelcomeBanner = _resolveComponent("WelcomeBanner")!
  const _component_IconLinks = _resolveComponent("IconLinks")!
  const _component_HeadshotIntro = _resolveComponent("HeadshotIntro")!
  const _component_CoreValues = _resolveComponent("CoreValues")!
  const _component_SchedulePrompt = _resolveComponent("SchedulePrompt")!
  const _component_HomeTestimonials = _resolveComponent("HomeTestimonials")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WelcomeBanner),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_IconLinks),
      _createVNode(_component_HeadshotIntro),
      _createVNode(_component_CoreValues),
      _createVNode(_component_SchedulePrompt),
      _createVNode(_component_HomeTestimonials)
    ])
  ], 64))
}