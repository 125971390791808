<template>
  <router-link
    :to="'/contact'"
    class="contact-us-button button-link mlr-auto flex align-c j-evenly"
  >
    <img
      class="logistics-icon"
      src="/icons/contact-us-icon.svg"
      alt="phone and envelope"
    />
    <div class="call-to-action">
      Contact us to schedule your
      <span class="no-wrap-text">session&nbsp;&nbsp;></span>
    </div>
  </router-link>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ContactUs",
});
</script>
<style scoped>
.contact-us-button {
  height: 4em;
  padding: 1em;
  margin: 2em auto;
  max-width: 600px;
}
.call-to-action {
  color: var(--care-blue-faint);
}
.logistics-icon {
  filter: opacity(50%) invert();
  height: 5em;
  width: 5em;
  margin-right: 2em;
}
@media (min-width: 901px) {
  .contact-us-button {
    width: 600px;
  }
  .call-to-action {
    font-size: 1.5em;
  }
}
@media (max-width: 900px) {
  .contact-us-button {
    width: 80%;
  }
  .call-to-action {
    font-size: clamp(1em, 3.5vw, 6vw);
  }
}
</style>
