<template>
  <div id="alert-modal" class="back-blue text-yellow" v-if="isOpen">
    <div class="static-large mlr-auto">
      {{ successOrFailure ? "Message Sent!" : "Error..." }}
    </div>
    <div class="alert-message">
      {{ message }}
    </div>
    <div
      v-if="isOpen && !successOrFailure"
      @click="closeAlertModal"
      class="email-button static-medium mlr-auto"
    >
      ok
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ResultModal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    successOrFailure: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  emits: ["close-modal"],
  methods: {
    closeAlertModal() {
      this.$emit("close-modal");
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen && this.successOrFailure) {
        setTimeout(() => {
          this.closeAlertModal();
        }, 3000);
      }
    },
  },
});
</script>

<style scoped>
#alert-modal {
  position: fixed;
  top: 35%;
  right: 50%;
  margin-right: -216px;
  padding: 16px;
  width: 400px;
  text-align: center;
  border: 1px solid white;
}
.alert-message {
  margin-bottom: 1em;
}
.email-button {
  color: white;
  background-color: var(--care-blue-primary);
  padding: 0.5em;
  width: 100px;
  cursor: pointer;
  transition: 0.05s;
  border: 1px solid white;
  border-radius: 8px;
}

.email-button:hover {
  background-color: var(--care-blue-hover);
  transition: 0.05s;
}
</style>
