<template>
  <div>
    <div id="resources-header" class="w-100">
      <div id="resources-header-text" class="static-large text-blue">
        Autism<br />Resources
      </div>
    </div>
    <div id="resources-links" class="back-blue">
      <div
        v-for="(section, sectionKey) in resources"
        :key="sectionKey"
        class="list-section mlr-auto"
      >
        <h1 class="text-white">{{ makeTitle(sectionKey) }}</h1>
        <LinkList
          class="flex column"
          :link-section="section"
          :text-color-class="'text-link-yellow'"
          :internal="false"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Resources } from "../constants/resources";
import LinkList from "./LinkList.vue";
import { titleize } from "../utils/titleize";

export default defineComponent({
  name: "ResourcesMain",
  components: {
    LinkList,
  },
  data() {
    return {
      resources: Resources,
    };
  },
  methods: {
    makeTitle(str: string) {
      return titleize(str);
    },
  },
});
</script>
<style>
#resources-links {
  padding: 6em 0;
}
@media (min-width: 901px) {
  #resources-header {
    background-image: url("/public/images/Webiste-Pic-1-1_2.png");
    height: 37em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right 0%;
    /* min-width: 1200px; */
    overflow: hidden;
  }

  #resources-header-text {
    overflow: visible;
    position: relative;
    left: 5vw;
    font-size: 5vw;
    top: 50%;
    background-color: white;
    width: fit-content;
    padding: 0.5em;
  }
}
@media (max-width: 900px) {
  #resources-header {
    display: flex;
    background-image: url("/public/images/Webiste-Pic-1-1_2.png");
    height: 15em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right 0%;
    min-width: 375px;
    overflow: hidden;
  }

  #resources-header-text {
    overflow: visible;
    align-self: flex-end;
    font-size: clamp(24px, 6vw, 6vw);
    margin: 0.75em;
    background-color: white;
    width: fit-content;
    padding: 0.5em;
  }
}
</style>
