import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "@/components/HomePage.vue";
import ServicesMain from "@/components/ServicesMain.vue";
import ResourcesMain from "@/components/ResourcesMain.vue";
import Contact from "@/components/Contact.vue";
import AboutUs from "@/components/About.vue";
import { ProfessionalDevelopmentServices } from "@/constants/services";
import { ConsultationServices } from "@/constants/services";
import ServiceDetails from "@/components/ServiceDetails.vue";
import ProfessionalDevelopment from "@/components/ProfessionalDevelopment.vue";
import Consultations from "@/components/Consultations.vue";

const combinedConsultations = [
  ...ConsultationServices.otherTypesOfClassroomConsultativeSupport,
  ...ConsultationServices.districtServices,
  // ...ConsultationServices.otherDistrictLevelOfferings,
  ...ConsultationServices.inclusiveEducationPractices,
];
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
  },
  {
    path: "/services",
    name: "services",
    component: ServicesMain,
  },
  {
    path: "/services/professional-development",
    name: "professional-development",
    component: ProfessionalDevelopment,
  },
  {
    path: "/services/professional-development/:route",
    name: "professional development details",
    component: ServiceDetails,
    props: {
      allServices:
        ProfessionalDevelopmentServices.trainingForEvaluationPersonnel,
    },
  },
  {
    path: "/services/consultations",
    name: "consultations",
    component: Consultations,
  },
  {
    path: "/services/consultations/:route",
    name: "consultation details",
    component: ServiceDetails,
    props: { allServices: combinedConsultations },
  },
  {
    path: "/resources",
    name: "resources",
    component: ResourcesMain,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
