<template>
  <div id="schedule-prompt" class="flex j-evenly section-max-w mlr-auto">
    <div id="schedule-prompt-text-container" class="flex column align-e">
      <div class="schedule-prompt-text mb-1">
        <div class="prompt-header highlight-font-size blurb-highlight">
          We positively change lives
        </div>
        <div class="sub-highlight-font-size">
          through Applied Behavior Analysis
        </div>
      </div>
      <router-link
        to="/contact"
        id="schedule-prompt-link"
        class="schedule-prompt-text midsize-text text-link-basic"
      >
        Schedule your
        <span class="no-wrap-text">consultation &nbsp;></span>
      </router-link>
    </div>
    <img
      id="schedule-prompt-image"
      src="/images/AdobeStock_271740984-scaled.jpeg"
      alt="specialist helping student"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "SchedulePrompt",
});
</script>
<style scoped>
.prompt-header {
  font-weight: 700;
}
@media (min-width: 901px) {
  /* current */
  #schedule-prompt {
    height: 20em;
    margin-bottom: 4em;
  }

  #schedule-prompt-image {
    height: 20em;
    margin: auto 0;
  }
  #schedule-prompt-text-container {
    color: var(--care-blue-primary);
    justify-content: space-evenly;
  }
  #schedule-prompt-link {
    color: var(--care-blue-primary);
  }
  #schedule-prompt-link:hover {
    color: var(--care-blue-hover);
  }
}
@media (max-width: 900px) {
  #schedule-prompt {
    justify-content: flex-end;
    height: 25em;
    background-image: url("/public/images/AdobeStock_271740984-scaled.jpeg");
    background-size: cover;
    background-position: 10%;
    color: white;
  }

  #schedule-prompt-image {
    display: none;
  }

  #schedule-prompt-text-container {
    backdrop-filter: grayscale(100%) brightness(50%) sepia(100%)
      hue-rotate(190deg);
    color: white;
    padding: 16px 16px 16px 50%;
    width: 600px;
    max-width: 100vw;
    justify-content: flex-end;
  }

  .schedule-prompt-text {
    padding: 0.5em;
    background-color: var(--care-blue-opaque);
    width: 450px;
    max-width: fit-content;
  }
  .prompt-header {
    color: var(--care-yellow-primary);
  }
  #schedule-prompt-link {
    color: white;
  }
  #schedule-prompt-link:hover {
    color: var(--care-blue-faint);
  }
}
</style>
